import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@nextui-org/react"; // Update this import
import InfoIcon from "@mui/icons-material/Info"; // Update this import

const ShopCardLarge = ({ shop, onRegisterClick }) => {
  const { t } = useTranslation();

  return (
    <a
      href={`/view-shop/${shop.id}`}
      className="shadow-lg bg-white border-1 p-6 h-full w-full rounded-2xl flex flex-col justify-center items-center hover:scale-105 overflow-hidden transition-transform duration-300"
      aria-label={`Visit ${shop.name} and earn cashback`}
      // target="_blank"
      // rel="noopener noreferrer"
    >
      <div className="w-40 h-40 flex justify-center mx-auto mb-4">
        <img
          alt={`${shop.name} logo`}
          src={shop.logo_url}
          width={158}
          height={48}
          className="max-h-40 max-w-40 object-contain scale-110"
        />
      </div>
      <div className="font-semibold text-center mb-2">{shop.name}</div>
      <div className="my-4 w-full">
        <hr />
      </div>
      <div
        className="text-sm text-center"
        dangerouslySetInnerHTML={{
          __html: t("cez Bilionbuy získate"),
        }}
      ></div>
      {shop.direct_cashback && (
        <div className="text-green font-semibold my-2 flex flex-col gap-1 text-xs items-center ">
          {/* First row */}
          <div className="flex flex-nowrap gap-1 justify-center w-full">
            <span className="bg-green text-white rounded-3xl px-2 py-1 uppercase whitespace-nowrap">
              cashback{" "}
              {shop.additional_commision_groups &&
              shop.additional_commision_groups.length > 0
                ? "až "
                : " "}
              <strong className="text-md">{shop.max_cashback} %</strong>
            </span>
            <span className="bg-green text-white rounded-3xl px-2 py-1 font-bold uppercase whitespace-nowrap">
              DRC {shop.referal_cashback}
            </span>
          </div>

          {/* Second row */}
          <div className="flex justify-center items-center w-full mt-1">
            <span className="bg-green text-white rounded-3xl px-2 py-1 font-bold uppercase">
              {shop.structural_cashback} {t("BODOV")}{" "}
            </span>
            <Tooltip
              content={
                <div
                  className="text-sm text-center"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "Výška percent cashbacku a DRC sa vypočítava z nakúpenej sumy bez DPH, bez poštovného. <br /> Počet bodov je vypočítaný z jednorazovej platby 1000 EUR vrátane DPH bez poštovného"
                    ),
                  }}
                ></div>
              }
            >
              <InfoIcon fontSize="small" className="ml-1 text-lightgray/50" />
            </Tooltip>
          </div>
        </div>
      )}
    </a>
  );
};

export default ShopCardLarge;
