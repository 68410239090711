import { Avatar, AvatarGroup, Tooltip, Button } from "@nextui-org/react";
import { Image } from "@nextui-org/react";
import HomepageCarousel from "./HomepageCarousel";
import { FaLink } from "react-icons/fa";
import { useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { useTranslation } from "react-i18next";
import { TextEffect } from "../animations/TextEffect.tsx";
import { Trans } from "react-i18next";

const carousel_images = [
  "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/hero_1.png",
  "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/hero_2.png",
  "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/hero_3.png",
];

const circles = [
  "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/circle%201.png",
  "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/circle%202.png",
  "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/cloud%206.png",
];

export default function Hero({ auth }) {
  const { t } = useTranslation();

  // State to manage the text based on carousel slide
  const [currentText, setCurrentText] = useState(t("herotext.title"));
  const [currentTextColor, setCurrentTextColor] = useState(
    t("herotext.titlecolor")
  );

  const copyRefferalCode = () => {
    navigator.clipboard.writeText(
      "https://www.bilionbuy.com/register?ref=" +
        localStorage.getItem("refcode")
    );
  };

  // Set up state for the Embla Carousel instance
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, align: "start" },
    [Autoplay({ delay: 7000 })]
  );

  // Track selected slide index
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());

    // Set text based on selected slide index
    switch (emblaApi.selectedScrollSnap()) {
      case 0:
        setCurrentText(t("Porovnaj kvalitu,  cenu a zarábaj úplne ZDARMA!"));
        setCurrentTextColor(t("t1"));
        break;
      case 1:
        setCurrentText(t("Bilionbuy je porovnávač kvality, ceny a cashbacku"));
        setCurrentTextColor(t("t2"));
        break;
      case 2:
        setCurrentText(
          t(
            "Porovnajte široké spektrum produktov z rôznych internetových obchodov."
          )
        );
        setCurrentTextColor(t("t3"));
        break;
      default:
        setCurrentText(t("Porovnaj kvalitu,  cenu a zarábaj úplne ZDARMA!"));
        setCurrentTextColor(t("t1"));
    }
  }, [emblaApi, t]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on("select", onSelect); // Register the callback with the carousel
    onSelect(); // Initial selection
  }, [emblaApi, onSelect]);

  return (
    <>
      <div className="flex flex-col max-w-screen-2xl mx-auto overflow-hidden">
        <div className="flex flex-col md:flex-row  ">
          <div className="flex mx-auto w-full justify-between">
            <div className="lg:w-auto md:w-full sm:w-full p-0  lg:my-auto h-full  bg-gradient-to-r from-white to-transparent z-10 flex flex-col justify-center">
              <div className="px-8 2xl:px-0 ">
                <div className="lg:pt-16 md:pt-24 py-8 text-center md:text-left">
                  <a className="bg-pink px-6 md:px-8 py-2 rounded-3xl text-white uppercase font-bold animate-pulse">
                    {t("zadarmo")}
                  </a>
                </div>
                <h1 className="font-bold lg:text-7xl md:text-5xl text-4xl py-8 lg:py-10 leading-tight text-center md:text-left lg:h-[calc(3*1.5em)] h-[calc(4*1.5em)] flex flex-col justify-center overflow-hidden">
                  <Trans i18nKey="herotext.title1_1">
                    {/* 0: TextEffect wrapping part */}
                    <span className="block">{currentText}</span>
                    {/* 1: a tag wrapping with gradient color */}
                    <a className="bg-gradient-to-r from-[#3769EF] to-[#FD60A9] bg-clip-text text-transparent block">
                      {currentTextColor}
                    </a>
                  </Trans>
                </h1>
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-center py-6 md:py-10">
                  <div>
                    <AvatarGroup isBordered>
                      <Avatar
                        src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
                        alt="User 1"
                      />
                      <Avatar
                        src="https://i.pravatar.cc/150?u=a04258a2462d826712d"
                        alt="User 2"
                      />
                      <Avatar
                        src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
                        alt="User 3"
                      />
                      <Avatar
                        src="https://i.pravatar.cc/150?u=a04258114e29026302d"
                        alt="User 4"
                      />
                    </AvatarGroup>
                  </div>
                  <div className="font-regular lg:text-xl md:text-lg text-sm text-center md:text-left">
                    <span
                      className="text-blue font-bold"
                      dangerouslySetInnerHTML={{
                        __html: t("herotext.smalltitle"),
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("herotext.description"),
                      }}
                    />
                  </div>
                </div>
                <div>
                  {auth ? (
                    <div className="flex flex-row gap-2 items-center md:justify-start justify-center">
                      <Button
                        as="a"
                        href="/my-zone#invites"
                        className="bg-blue text-white rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10"
                      >
                        {t("Pozvi priateľa")} &rarr;
                      </Button>
                      <Tooltip content={t("click_for_linkcopy")}>
                        <div
                          className="p-3 md:p-4 rounded-full border-1 border-primary hover:cursor-pointer"
                          onClick={copyRefferalCode}
                        >
                          <FaLink className="text-primary" />
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex flex-row gap-2 items-center md:justify-start justify-center">
                      <Button
                        as="a"
                        href="/register"
                        className="bg-blue text-white rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10"
                      >
                        {t("Registrácia zdarma")} &rarr;
                      </Button>
                      {/* <Button
                        as="a"
                        href="/blog#vyhody-cashbacku/"
                        className="bg-transparent text-blue border-blue border rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10"
                      >
                        {t("Viac informácií")}
                      </Button> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-[600px]  lg:flex hidden ">
              <div className="embla  mt-12 ">
                <div
                  className="embla_viewport flex flex-col w-full h-[700px] "
                  ref={emblaRef}
                >
                  <div className="embla_container flex flex-row w-full h-full ">
                    <div className="relative embla_slide min-w-0 flex-none w-full">
                      <div className=" w-[600px] h-full bg-primary rounded-3xl">
                        &nbsp;
                      </div>
                      <img
                        src={circles[0]}
                        alt=""
                        className="absolute top-32 left-64"
                      />
                      <img
                        src={circles[1]}
                        alt=""
                        className="absolute top-6 left-6"
                      />
                      <img src={circles[3]} alt="" className="absolute" />
                      <img
                        className="absolute bottom-0 -left-24"
                        src={carousel_images[0]}
                        alt="Hero 1"
                      />
                    </div>
                    <div className="relative embla_slide min-w-0 flex-none w-full">
                      <div className=" w-[600px] h-full bg-pink rounded-3xl">
                        &nbsp;
                      </div>
                      <img
                        src={circles[0]}
                        alt=""
                        className="absolute top-32 left-64"
                      />
                      <img
                        src={circles[1]}
                        alt=""
                        className="absolute top-6 left-6"
                      />

                      <img
                        className="absolute h-[600px] bottom-0 -left-64"
                        src={carousel_images[1]}
                        alt="Hero 2"
                      />
                    </div>
                    <div className="relative embla_slide min-w-0 flex-none w-full">
                      <div className="w-[600px] h-full bg-fuchsia-600 rounded-3xl">
                        &nbsp;
                      </div>
                      <img
                        src={circles[0]}
                        alt=""
                        className="absolute top-32 left-64"
                      />
                      <img
                        src={circles[1]}
                        alt=""
                        className="absolute top-6 left-6"
                      />

                      <img
                        src={carousel_images[2]}
                        alt="Hero 3"
                        className="absolute h-[600px] bottom-0 -left-10"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4">
        {/* <HomepageCarousel /> */}
        {/* <hr /> */}
      </div>
    </>
  );
}
