import React, { useState, useEffect } from "react";
import SignOut from "./SignOut";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  User,
  Link,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Tooltip,
} from "@nextui-org/react";

import InviteFriend from "./InviteFriend";
import SupportMail from "./SupportMail";
import { useTranslation } from "react-i18next";
import api from "../services/api";

export default function ProfileComponent() {
  const { t } = useTranslation();
  const img_url = localStorage.getItem("user_image");
  const username = localStorage.getItem("username");
  const scrollBehavior = "inside";
  const [userPoints, setUserPoints] = useState(() => {
    const cachedPoints = localStorage.getItem("userPoints");
    return cachedPoints ? JSON.parse(cachedPoints) : { own: 0, structure: 0 };
  });

  useEffect(() => {
    const fetchUserPoints = async () => {
      const cachedPoints = localStorage.getItem("userPoints");
      const cachedTimestamp = localStorage.getItem("userPointsTimestamp");
      const currentTime = new Date().getTime();

      // Check if cached data exists and is less than 5 minutes old
      if (
        cachedPoints &&
        cachedTimestamp &&
        currentTime - parseInt(cachedTimestamp) < 5 * 60 * 1000
      ) {
        setUserPoints(JSON.parse(cachedPoints));
        return;
      }

      try {
        const response = await api.get("/get-user-navbar-points");
        const { own_points, structure_points } = response.data;
        const newPoints = {
          own: own_points || 0,
          structure: structure_points || 0,
        };
        setUserPoints(newPoints);
        localStorage.setItem("userPoints", JSON.stringify(newPoints));
        localStorage.setItem("userPointsTimestamp", currentTime.toString());
      } catch (error) {
        console.error("Error fetching user points:", error);
      }
    };

    fetchUserPoints();
  }, []);

  const { isOpen: isInviteOpen, onOpenChange: onInviteOpenChange } =
    useDisclosure();

  const { isOpen: isSupportOpen, onOpenChange: onSupportOpenChange } =
    useDisclosure();

  const checkAdmin = () => {
    if (localStorage.getItem("user_permissions")) {
      const permissions = localStorage.getItem("user_permissions");
      return permissions.includes("administrator");
    }
    return false;
  };

  const isAdmin = checkAdmin();

  return (
    <>
      <div className="flex items-center md:gap-4  min-w-[46px]">
        <div className="flex">
          <Tooltip
            content={
              <div className="md:flex hidden">
                <strong>
                  {t("Vlastné body")} / {t("Všetky body")}{" "}
                </strong>{" "}
                {t("za tento mesiac")}
              </div>
            }
          >
            <div
              className="text-sm font-medium mr-2 cursor-pointer hover:bg-white rounded-3xl px-2 py-1 transition-colors"
              onClick={() => (window.location.href = "/my-zone")}
            >
              {userPoints.own} / {userPoints.structure}
            </div>
          </Tooltip>
        </div>
        <Dropdown placement="bottom-start">
          <DropdownTrigger>
            <User
              as="button"
              avatarProps={{
                isBordered: true,
                src: img_url,
              }}
              className="transition-transform z-0"
            />
          </DropdownTrigger>
          <DropdownMenu aria-label="User Actions" variant="flat">
            <DropdownItem
              key="profile"
              className="h-14 gap-2 hover:text-blue"
              color="primary"
            >
              <p className="font-bold">{t("Prihlásený ako")}</p>
              <p className="font-bold">{username}</p>
            </DropdownItem>
            {isAdmin && (
              <DropdownItem
                key="admin_panel"
                as={Link}
                className="text-black"
                href="/dashboard"
              >
                Admin panel
              </DropdownItem>
            )}
            <DropdownItem
              key="my_structure"
              as={Link}
              className="text-black"
              href="/my-zone"
            >
              {t("Moja kariéra")}
            </DropdownItem>
            <DropdownItem
              key="partner_zone"
              as={Link}
              className="text-black"
              href="/partner-zone"
            >
              {t("Zóna pre partnera")}
            </DropdownItem>
            <DropdownItem key="invite_friend" onClick={onInviteOpenChange}>
              {t("Pozvať priateľa")}
            </DropdownItem>
            <DropdownItem key="help_and_feedback" onClick={onSupportOpenChange}>
              {t("Pomoc a podpora")}
            </DropdownItem>

            <DropdownItem key="logout" color="danger">
              <SignOut t={t} />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      {/* Invite Friend Modal */}
      <Modal
        isOpen={isInviteOpen}
        onOpenChange={onInviteOpenChange}
        scrollBehavior={scrollBehavior}
        fullScreen={true} // Ensure the modal takes full screen on smaller devices or large content
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {t("Pozvať priateľa")}
              </ModalHeader>
              <ModalBody>
                <InviteFriend />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  {t("Zatvoriť")}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* Support Mail Modal */}
      <Modal
        isOpen={isSupportOpen}
        onOpenChange={onSupportOpenChange}
        scrollBehavior={scrollBehavior}
        fullScreen={true} // Ensure the modal takes full screen on smaller devices or large content
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {t("Pomoc a podpora")}
              </ModalHeader>
              <ModalBody>
                <SupportMail />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  {t("Zatvoriť")}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
