import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  TablePagination,
  Autocomplete,
  CircularProgress,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Save, Clear, Close } from "@mui/icons-material";
import debounce from "lodash/debounce";
import api from "../../services/api";
import { toast } from "react-toastify";
import CategoryProductsButton from "./CategoryProductsButton";

const CategoryAssignmentTable = () => {
  const [categoryCorrections, setCategoryCorrections] = useState([]);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updatingRows, setUpdatingRows] = useState({});
  const [selectedCategories, setSelectedCategories] = useState({});
  const abortControllerRef = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState("sk");

  const fetchCategoryCorrections = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        country: selectedCountry,
      };

      if (filter.trim() !== "") {
        params.original_category = filter;
      }

      const response = await api.get("get-category-corrections/", { params });
      const { results, pagination } = response.data;
      setCategoryCorrections(results);
      setTotalCount(pagination.count);

      const initialSelectedCategories = {};
      for (const correction of results) {
        if (correction.our_category) {
          initialSelectedCategories[correction.id] = {
            id: correction.our_category,
            category_fullname: correction.assigned_category_fullname,
          };
        } else {
          initialSelectedCategories[correction.id] = null;
        }
      }
      setSelectedCategories(initialSelectedCategories);
    } catch (error) {
      console.error("Error fetching category corrections:", error);
      toast.error("Failed to load category corrections. Please try again.");
      setCategoryCorrections([]);
      setTotalCount(0);
    } finally {
      setLoading(false);
    }
  }, [filter, page, rowsPerPage, selectedCountry]);

  useEffect(() => {
    fetchCategoryCorrections();
  }, [fetchCategoryCorrections]);

  const debouncedFetch = useCallback(
    debounce(() => {
      setPage(0);
      fetchCategoryCorrections();
    }, 300),
    [fetchCategoryCorrections]
  );

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    debouncedFetch();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateCorrection = async (id) => {
    setUpdatingRows((prev) => ({ ...prev, [id]: true }));
    try {
      const newCategory = selectedCategories[id];
      const response = await api.post("update-category-correction/", {
        id,
        our_category: newCategory?.id || null,
        country: selectedCountry,
      });

      if (response.status === 200) {
        setCategoryCorrections((prevCorrections) =>
          prevCorrections.map((correction) =>
            correction.id === id
              ? { ...correction, our_category: newCategory?.id || null }
              : correction
          )
        );
        setSelectedCategories((prev) => ({
          ...prev,
          [id]: newCategory,
        }));
        toast.success("Korekcia kategórie bola úspešne aktualizovaná");
      } else {
        throw new Error("Nepodarilo sa aktualizovať korekciu kategórie");
      }
    } catch (error) {
      console.error("Error updating category correction:", error);
      toast.error(
        "Nepodarilo sa aktualizovať korekciu kategórie. Skúste to znova, prosím."
      );
    } finally {
      setUpdatingRows((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleClearCategory = async (id) => {
    setUpdatingRows((prev) => ({ ...prev, [id]: true }));
    try {
      const response = await api.post("update-category-correction/", {
        id,
        our_category: null,
      });

      if (response.status === 200) {
        setCategoryCorrections((prevCorrections) =>
          prevCorrections.map((correction) =>
            correction.id === id
              ? { ...correction, our_category: null }
              : correction
          )
        );
        setSelectedCategories((prev) => ({ ...prev, [id]: null }));
        toast.success("Kategória bola úspešne vymazaná");
      } else {
        throw new Error("Nepodarilo sa vymazať kategóriu");
      }
    } catch (error) {
      console.error("Error clearing category:", error);
      toast.error("Nepodarilo sa vymazať kategóriu. Skúste to znova, prosím.");
    } finally {
      setUpdatingRows((prev) => ({ ...prev, [id]: false }));
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.length > 2) {
        try {
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }

          abortControllerRef.current = new AbortController();

          const response = await api.get(`search-categories/?query=${query}`, {
            signal: abortControllerRef.current.signal,
          });
          const newOptions = Array.isArray(response.data) ? response.data : [];
          setCategoryOptions(newOptions);
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("Request aborted");
          } else {
            console.error("Error searching categories: ", error);
          }
        }
      }
    }, 300),
    []
  );

  const handleClearFilter = () => {
    setFilter("");
    debouncedFetch();
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setPage(0);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
          gap: "1rem",
        }}
      >
        <FormControl style={{ minWidth: 120 }}>
          <InputLabel>Krajina</InputLabel>
          <Select
            value={selectedCountry}
            label="Krajina"
            onChange={handleCountryChange}
          >
            <MenuItem value="sk">Slovensko</MenuItem>
            <MenuItem value="cz">Česko</MenuItem>
            <MenuItem value="pl">Poľsko</MenuItem>
            <MenuItem value="de">Nemecko</MenuItem>
            <MenuItem value="hu">Maďarsko</MenuItem>
            <MenuItem value="en">Anglicko</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Filtrovať podľa pôvodnej kategórie"
          variant="outlined"
          value={filter}
          onChange={handleFilterChange}
          style={{ flexGrow: 1 }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={handleClearFilter}
                style={{ visibility: filter ? "visible" : "hidden" }}
              >
                <Close />
              </IconButton>
            ),
          }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pôvodná kategória</TableCell>
              <TableCell>Naša kategória</TableCell>
              <TableCell>Akcia</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : categoryCorrections.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Neboli nájdené žiadne korekcie kategórií.
                </TableCell>
              </TableRow>
            ) : (
              categoryCorrections.map((correction) => (
                <TableRow key={correction.id}>
                  <TableCell className="max-w-[350px]">
                    <div className="flex items-center">
                      <CategoryProductsButton
                        originalCategory={correction.original_category}
                      />
                      <span className="ml-2">
                        {correction.original_category}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Autocomplete
                        disablePortal
                        id={`category-selector-${correction.id}`}
                        options={categoryOptions}
                        getOptionLabel={(option) =>
                          option?.category_fullname || ""
                        }
                        value={selectedCategories[correction.id] || null}
                        onChange={(event, newValue) => {
                          setSelectedCategories((prev) => ({
                            ...prev,
                            [correction.id]: newValue,
                          }));
                        }}
                        onInputChange={(event, newInputValue) => {
                          debouncedSearch(newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Vyberte kategóriu"
                            variant="outlined"
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        style={{ flexGrow: 1, marginRight: "8px" }}
                      />
                      <IconButton
                        onClick={() => handleUpdateCorrection(correction.id)}
                        disabled={updatingRows[correction.id]}
                      >
                        {updatingRows[correction.id] ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Save />
                        )}
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleClearCategory(correction.id)}
                      disabled={updatingRows[correction.id]}
                      startIcon={<Clear />}
                    >
                      Vymazať
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Riadkov na stránku:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} z ${count !== -1 ? count : `viac ako ${to}`}`
        }
      />
    </div>
  );
};

export default CategoryAssignmentTable;
