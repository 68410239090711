import React, { useEffect, useState, useCallback, useRef } from "react";
import api from "../services/api";
import { Spinner, Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import Translate from "./TranslationAPI";
import { useNavigate } from "react-router-dom";
import {
  categories,
  foodCategories,
  servicesCategories,
  travelCategories,
  eventCategories,
  bazarCategories,
} from "../data/categories";
import { ShopCardMenu } from "./ShopCardMenu";
import ShopCard from "./ShopCard";
import ShopCardLarge from "./ShopCardLarge";
import { useAdultContent } from "../providers/AdultContentProvider";

const ProductCategories = ({ category }) => {
  const [productCategories, setProductCategories] = useState([]);
  const [openCategory, setOpenCategory] = useState(null);
  const [subCategories, setSubCategories] = useState({});
  const [shops, setShops] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [cachedData, setCachedData] = useState({
    subCategories: {},
    shops: {},
  });
  const [activeButton, setActiveButton] = useState("categories");

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const subcategoriesRef = useRef(null);
  const shopsRef = useRef(null);
  const { requestAgeConfirmation } = useAdultContent();

  useEffect(() => {
    // Function to check if the screen is mobile-sized
    const isMobileView = () => window.innerWidth <= 768;

    if (openCategory && subcategoriesRef.current && isMobileView()) {
      const headerOffset = 120; // Adjust this value to match the height of your sticky header
      const elementPosition =
        subcategoriesRef.current.getBoundingClientRect().top +
        window.pageYOffset;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [openCategory]);

  const fetchSubCategories = useCallback(async (category) => {
    try {
      const response = await api.get("subcats", { params: { category } });
      return response.data.subcategories || [];
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return [];
      }
      console.error("Error fetching subcategories: ", error);
      throw error;
    }
  }, []);

  const fetchCategoryShops = useCallback(async (category) => {
    try {
      const response = await api.get("category-shops", {
        params: { category },
      });
      return response.data || [];
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return [];
      }
      console.error("Error fetching shops: ", error);
      throw error;
    }
  }, []);

  const getCategoryName = useCallback(
    (category) => {
      const currentLang = i18n.language;
      return currentLang === "cs"
        ? category.category_name_cz
        : category.category_name_sk;
    },
    [i18n.language]
  );

  const handleCategoryClick = useCallback(
    async (category) => {
      const categoryName = getCategoryName(category);
      const categoryTag = category.tag_name;

      // Add debug logs
      console.log("Category clicked:", category);
      console.log("Category tag:", categoryTag);

      if (categoryTag === "Erotické pomôcky") {
        console.log("Adult content detected, showing modal");
        requestAgeConfirmation(() => {
          console.log("Age confirmed, proceeding with navigation");
          handleCategoryNavigation(category);
        });
        return;
      }

      handleCategoryNavigation(category);
    },
    [getCategoryName, requestAgeConfirmation]
  );

  const handleCategoryNavigation = async (category) => {
    const categoryName = getCategoryName(category);
    const categoryTag =
      category.tag_name ||
      category.category_name_sk ||
      category.category_name_cz;

    console.log("Category Name:", categoryName);
    console.log("Category Tag:", categoryTag);

    if (category.parent_category === "food") {
      navigate(`/category/${categoryName}`);
      return;
    }

    if (openCategory === categoryName) {
      setOpenCategory(null);
      return;
    }

    setOpenCategory(categoryName);
    setIsLoading(true);

    // Fetch subcategories and shops independently
    try {
      // Fetch subcategories
      if (cachedData.subCategories[categoryName]) {
        setSubCategories((prev) => ({
          ...prev,
          [categoryName]: cachedData.subCategories[categoryName],
        }));
      } else {
        const subCategoriesData = await fetchSubCategories(categoryName);
        setCachedData((prev) => ({
          ...prev,
          subCategories: {
            ...prev.subCategories,
            [categoryName]: subCategoriesData,
          },
        }));
        setSubCategories((prev) => ({
          ...prev,
          [categoryName]: subCategoriesData || [],
        }));
      }
    } catch (error) {
      console.error("Error loading subcategories: ", error);
    }

    // Fetch shops separately
    try {
      if (cachedData.shops[categoryName]) {
        setShops((prev) => ({
          ...prev,
          [categoryName]: cachedData.shops[categoryName],
        }));
      } else {
        const shopsData = await fetchCategoryShops(categoryTag);
        console.log("API Response - Shops:", shopsData);

        setCachedData((prev) => ({
          ...prev,
          shops: { ...prev.shops, [categoryName]: shopsData },
        }));
        setShops((prev) => ({
          ...prev,
          [categoryName]: shopsData,
        }));
      }
    } catch (error) {
      console.error("Error loading shops: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const categoryMap = {
      shopping: categories,
      food: foodCategories.map((cat) => ({
        ...cat,
        onClick: () => navigate(`/category/${getCategoryName(cat)}`),
      })),
      travel: travelCategories,
      services: servicesCategories,
      event: eventCategories,
      second_hand: bazarCategories,
    };

    setProductCategories(categoryMap[category] || []);
    setOpenCategory(null);
  }, [category, navigate, getCategoryName]);

  const scrollToSubcategories = () => {
    setActiveButton("categories");
    if (subcategoriesRef.current) {
      const headerOffset = 160; // Adjust this value to match the height of your sticky headerres
      const elementPosition =
        subcategoriesRef.current.getBoundingClientRect().top +
        window.pageYOffset;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const navigateToCatalog = () => {
    setActiveButton("catalog");
    navigate(`/category/${openCategory}`);
  };

  const scrollToShops = () => {
    setActiveButton("shops");
    if (shopsRef.current) {
      const headerOffset = 160; // Adjust this value to match the height of your sticky header
      const elementPosition =
        shopsRef.current.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleRegisterClick = (e, shop) => {
    // Implement your click registration logic here
    console.log("Shop clicked:", shop.name);
  };

  return (
    <div className="px-8 py-6 max-w-screen-2xl border-bottom-2 mx-auto bg-white">
      <h1 className="text-3xl font-bold pb-3 uppercase lg:block hidden">
        {t("Kategórie")}
      </h1>
      <hr />

      <div className="flex flex-col gap-4">
        {/* Categories Menu */}
        <div className="flex flex-wrap text-lg py-6">
          {productCategories.map((categoryItem) => {
            const categoryName = getCategoryName(categoryItem);
            return (
              <div
                key={categoryName}
                className={`flex items-center p-2 rounded-lg cursor-pointer m-2 ${
                  openCategory === categoryName
                    ? "bg-gray-200"
                    : "hover:bg-slate-50"
                }`}
                onClick={() =>
                  categoryItem.onClick
                    ? categoryItem.onClick()
                    : handleCategoryClick(categoryItem)
                }
              >
                <h2 className="text-lg font-semibold">
                  <div className="flex flex-row gap-2 items-center">
                    <categoryItem.icon />
                    {categoryName}
                  </div>
                </h2>
              </div>
            );
          })}
        </div>

        {openCategory && (
          <>
            {/* Updated left-aligned buttons with active state, visible only for shopping category */}
            {category === "shopping" && (
              <div className="flex mb-8 ml-4 gap-8 flex-wrap">
                <button
                  className={`text-lightgray hover:text-black transition-colors duration-300 ${
                    activeButton === "categories"
                      ? "text-white bg-primary px-4 py-1 rounded-lg"
                      : ""
                  }`}
                  onClick={scrollToSubcategories}
                >
                  {t("Kategórie")}
                </button>
                <button
                  className={`text-lightgray hover:text-black transition-colors duration-300 ${
                    activeButton === "catalog"
                      ? "text-white bg-primary px-4 py-1 rounded-lg"
                      : ""
                  }`}
                  onClick={navigateToCatalog}
                >
                  {t("Katalóg")}
                </button>
              </div>
            )}
            <hr />

            {/* Subcategories Section */}
            <div ref={subcategoriesRef}>
              {isLoading ? (
                <div className="mx-auto my-24">
                  <Spinner />
                </div>
              ) : (
                <div className="px-8">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-4 pt-4 mb-4">
                    {(subCategories[openCategory] || []).map(
                      (subcategory) =>
                        subcategory.subcategories.length > 0 && (
                          <div key={subcategory.category_name} className="py-1">
                            <a
                              href={"/category/" + subcategory.category_name}
                              className="font-bold"
                            >
                              {subcategory.category_name}
                            </a>
                            <div className="text-lightgray flex flex-col gap-2 pt-2">
                              {subcategory.subcategories
                                .slice(0, 5)
                                .map((subsub) => (
                                  <a key={subsub} href={"/category/" + subsub}>
                                    {subsub}
                                  </a>
                                ))}
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Shops Section - Now independent from subcategories */}
            <div ref={shopsRef} className="px-8">
              <div className="flex flex-wrap gap-4 mb-12">
                {shops[openCategory]?.map((shop) => (
                  <div
                    key={shop.id}
                    className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
                  >
                    <ShopCardLarge
                      shop={shop}
                      onRegisterClick={handleRegisterClick}
                    />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <hr />
    </div>
  );
};

export default ProductCategories;
