import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  useDisclosure,
} from "@nextui-org/react";
import api from "../../services/api";
import ProductEditModal from "./ProductEditModal";

const CategoryProductsButton = ({ originalCategory }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const fetchCategoryProducts = async (pageNumber = 1, query = "") => {
    try {
      setIsLoading(true);
      const response = await api.get("get-original-category-products/", {
        params: {
          page: pageNumber,
          name: query,
          original_category: originalCategory,
        },
      });
      setProducts(response.data.products || []);
      setTotalPages(response.data.pagination.total_pages);
      setTotalItems(response.data.pagination.total_items);
    } catch (error) {
      console.error("Error fetching category products", error);
      setProducts([]);
      setTotalPages(1);
      setTotalItems(0);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCategoryProducts(page, searchQuery);
    }
  }, [isOpen, page, originalCategory]);

  const handleSearch = () => {
    setPage(1);
    fetchCategoryProducts(1, searchQuery);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleProductClick = (product, e) => {
    e.preventDefault();
    setSelectedProduct(product);
    setIsEditModalOpen(true);
    onClose();
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedProduct(null);
    onOpen();
  };

  return (
    <>
      <button
        onClick={onOpen}
        className="px-2 py-1 bg-black font-bold text-white text-base rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
      </button>
      <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <div>
                  Produkty v pôvodnej kategórii:{" "}
                  <span className="font-bold">{originalCategory}</span>
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <input
                    type="text"
                    placeholder="Hľadať podľa názvu"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <Button
                    className="text-white bg-black"
                    onClick={handleSearch}
                  >
                    Hľadať
                  </Button>
                </div>
              </ModalHeader>
              <ModalBody>
                {isLoading ? (
                  <div className="mx-auto text-center justify-center">
                    <Spinner />
                  </div>
                ) : products.length === 0 ? (
                  <div className="pt-4 text-xl font-bold text-danger">
                    Nenašli sa žiadne produkty
                  </div>
                ) : (
                  <table className="min-w-full table-fixed divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th className="text-left">Produkt</th>
                        <th className="text-left">Cena</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {products.map((product) => (
                        <tr
                          key={product.id}
                          onClick={(e) => handleProductClick(product, e)}
                          className="cursor-pointer"
                        >
                          <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 hover:bg-slate-50">
                            <div className="flex flex-row gap-2 items-center">
                              <div>
                                <img
                                  src={product.product_image_url}
                                  className="max-w-16 max-h-16"
                                  alt=""
                                />
                              </div>
                              <div>{product.product_name}</div>
                            </div>
                          </td>
                          <td>{product.product_price_min}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </ModalBody>
              <ModalFooter>
                <div className="flex flex-row gap-2 items-center">
                  <div className="text-sm text-gray-700">
                    Strana {page} z {totalPages} | Položiek celkovo:{" "}
                    {totalItems}
                  </div>
                  <Button disabled={page <= 1} onClick={handlePreviousPage}>
                    Späť
                  </Button>
                  <Button
                    disabled={page >= totalPages}
                    onClick={handleNextPage}
                  >
                    Ďalej
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {selectedProduct && (
        <ProductEditModal
          isOpen={isEditModalOpen}
          onClose={handleEditModalClose}
          productId={selectedProduct.id}
          onUpdate={() => {
            fetchCategoryProducts(page, searchQuery);
          }}
        />
      )}
    </>
  );
};

export default CategoryProductsButton;
