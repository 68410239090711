import React, { useState, useEffect, useCallback } from "react";
import ItemCard from "./ItemCard";
import api from "../services/api";
import useEmblaCarousel from "embla-carousel-react";
import { Skeleton } from "@nextui-org/react"; // Make sure to import Skeleton

const ProductSlider = ({ title, text, type, fetchCall }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [chunkSize, setChunkSize] = useState(4); // Default to 4 for large screens

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await api.get(fetchCall);
      // Add a safety check for the response data
      const safeData = Array.isArray(response.data) ? response.data : [];
      setProducts(safeData);
    } catch (error) {
      console.error("Error fetching products:", error);
      setProducts([]); // Set to empty array in case of error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // Function to handle screen resizing and adjust chunk size
  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    if (width >= 1024) {
      setChunkSize(4); // Large screens
    } else if (width >= 768) {
      setChunkSize(2); // Medium screens
    } else {
      setChunkSize(1); // Small screens
    }
  }, []);

  useEffect(() => {
    handleResize(); // Initial check on mount
    window.addEventListener("resize", handleResize); // Listen for window resizing
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const previousSlide = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const nextSlide = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  // Function to split the products array into chunks
  const chunkArray = (array, size) => {
    if (!Array.isArray(array)) return [];
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };

  const productChunks = chunkArray(products, chunkSize);

  return (
    <div className="max-w-screen-2xl mx-auto px-4 md:px-8">
      <div className="flex flex-col lg:flex-row justify-between py-8 md:py-12">
        <h2 className="text-5xl md:text-5xl lg:text-7xl font-bold">{title}</h2>
        <p className="text-md md:text-lg lg:text-xl font-light max-w-[700px] mt-4 lg:mt-0">
          {text}
        </p>
      </div>
      <div className="relative flex items-center">
        {/* Previous slide button */}
        <div
          className="absolute -left-4 md:-left-8 lg:-left-12 flex items-center hover:cursor-pointer z-10"
          onClick={previousSlide}
          aria-label="Previous slide"
        >
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            focusable="false"
          >
            <path d="M23 34.5L11.5 23L23 11.5" stroke="black" strokeWidth="5" />
            <path
              d="M34.5 34.5L23 23L34.5 11.5"
              stroke="black"
              strokeWidth="5"
            />
          </svg>
        </div>

        <div
          className="embla overflow-hidden py-8 md:py-12 relative"
          ref={emblaRef}
        >
          <div className="embla__container flex relative">
            {loading ? (
              <div className="embla__slide flex-[0_0_100%] flex justify-between px-4 md:px-8">
                {Array.from({ length: chunkSize }).map((_, i) => (
                  <div
                    className="w-full sm:w-1/2 lg:w-1/4 px-2 md:px-4 flex justify-center"
                    key={i}
                  >
                    <div className="w-full max-w-[300px] bg-white rounded-lg shadow-lg p-4 flex flex-col">
                      <div className="relative pb-[100%] mb-4">
                        <Skeleton className="absolute inset-0 rounded-lg" />
                      </div>
                      <Skeleton className="h-6 w-3/4 mb-2" />
                      <Skeleton className="h-4 w-1/2 mb-2" />
                      <Skeleton className="h-4 w-2/3 mb-4" />
                      <div className="mt-auto">
                        <Skeleton className="h-10 w-full rounded-full" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              productChunks.map((chunk, index) => (
                <div
                  className="embla__slide flex-[0_0_100%] flex justify-between px-4 md:px-8"
                  key={index}
                >
                  {chunk.map((product, i) => (
                    <div
                      className="w-full sm:w-1/2 lg:w-1/4 px-2 md:px-4 flex justify-center"
                      key={i}
                    >
                      <ItemCard product={product} type={type} />
                    </div>
                  ))}
                </div>
              ))
            )}
          </div>
          <div className="absolute top-0 bottom-0 left-0 w-12 md:w-16 lg:w-24 bg-gradient-to-r from-white to-transparent pointer-events-none"></div>
          <div className="absolute top-0 bottom-0 right-0 w-12 md:w-16 lg:w-24 bg-gradient-to-l from-white to-transparent pointer-events-none"></div>
        </div>

        {/* Next slide button */}
        <div
          className="absolute -right-4 md:-right-8 lg:-right-12 flex items-center hover:cursor-pointer z-10"
          onClick={nextSlide}
          aria-label="Next slide"
        >
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            focusable="false"
          >
            <path d="M23 34.5L34.5 23L23 11.5" stroke="black" strokeWidth="5" />
            <path
              d="M11.5 34.5L23 23L11.5 11.5"
              stroke="black"
              strokeWidth="5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ProductSlider;
