import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import api from "../services/api";
import { useNavbar } from "./NavbarContext";
import { logo_url } from "../data/common";
import NewSearchBar from "./NewSearchBar";
import SearchBar from "./SearchBar";
import ProfileComponent from "./ProfileComponent";
import LocationSelector from "./LocationSelector";
import ProductCategories from "./ProductCategories";
import { FaAngleDown } from "react-icons/fa6";
import { HiMiniSquares2X2 } from "react-icons/hi2";

const MenuItem = ({
  name,
  category,
  soon,
  isNew,
  activeCategory,
  onClick,
  isMobile,
  tsoon,
  tnew,
}) => (
  <div
    className={`flex gap-2 items-center rounded-lg pl-4 pr-8 py-2 transition-fade duration-200 ${
      activeCategory === category && !isMobile ? "bg-primary text-white" : ""
    } ${soon ? "cursor-not-allowed" : "cursor-pointer"}`}
    onClick={(e) => {
      e.stopPropagation();
      if (!soon && onClick) {
        onClick(category, isMobile);
      }
    }}
    role="menuitem"
    aria-haspopup="true"
  >
    <FaAngleDown
      className={`transform transition-transform ${
        activeCategory === category ? "rotate-180" : ""
      }`}
      aria-hidden="true"
    />
    {name}
    {soon && (
      <span
        className="ml-1 text-xs bg-primary rounded-3xl text-white font-bold px-2 py-1"
        aria-label={`${name} coming soon`}
      >
        {tsoon}
      </span>
    )}
    {isNew && (
      <span
        className="ml-1 text-xs bg-pink rounded-3xl text-white font-bold px-2 py-1 animate-pulse"
        aria-label={`New category: ${name}`}
      >
        {tnew}
      </span>
    )}
  </div>
);

export default function BasicNavbar() {
  const { activeCategory, toggleCategory } = useNavbar();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [hoveringMenu, setHoveringMenu] = useState(false);
  const [hoveringContent, setHoveringContent] = useState(false);
  const location = localStorage.getItem("location");
  const [message, setMessage] = useState(null);
  const { t } = useTranslation();
  const productCategoriesRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState(() => {
    return localStorage.getItem("lastSearchQuery") || "";
  });
  const [currentLocale, setCurrentLocale] = useState(() => {
    return localStorage.getItem("locale") || "sk";
  });

  const handleSearchClick = (query) => {
    setSearchQuery(query);
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  const handleQueryChange = (newQuery) => {
    setSearchQuery(newQuery);
    localStorage.setItem("lastSearchQuery", newQuery);
  };

  const menuItems = [
    { name: t("Nakupovanie"), category: "shopping", soon: false, new: false },
    { name: t("Potraviny"), category: "food", soon: false, new: false },
    { name: t("Cestovanie"), category: "travel", soon: false, new: false },
    { name: t("Služby"), category: "services", soon: false, new: false },
    { name: t("Podujatia"), category: "event", soon: false, new: false },
    {
      name: t("Bazár"),
      category: "second_hand",
      soon: false,
      new: false,
    },
  ];

  const handleMenuItemClick = (category, isMobile) => {
    // Toggle the category if it's already open
    toggleCategory(activeCategory === category ? null : category);
    setMobileMenuOpen(false); // Close mobile menu if open

    if (productCategoriesRef.current) {
      const headerHeight = document.querySelector("header")?.offsetHeight || 0;
      const elementPosition =
        productCategoriesRef.current.getBoundingClientRect().top +
        window.pageYOffset;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const fetchUserInfo = async () => {
    try {
      const response = await api.get("get-user-data/");
      setUserInfo(response.data);
      localStorage.setItem("refcode", response.data.referral_code);
    } catch (error) {
      console.error("fetchUserData -> error", error);
    }
  };

  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    setIsAuthenticated(!!jwt);
    const refcode = localStorage.getItem("refcode");

    if (!refcode && jwt) {
      fetchUserInfo();
    }
  }, []);

  useEffect(() => {
    const handleLocaleChange = () => {
      setCurrentLocale(localStorage.getItem("locale") || "sk");
    };

    window.addEventListener("storage", handleLocaleChange);
    return () => window.removeEventListener("storage", handleLocaleChange);
  }, []);

  // Framer Motion variants for slide-in animation from left
  const slideInVariants = {
    hidden: { x: "-100%" },
    visible: { x: 0 },
    exit: { x: "-100%" },
  };

  return (
    <>
      {/* Desktop Navbar */}
      <div className="hidden lg:block sticky top-0 z-30">
        <header
          className={`sticky top-0 w-full backdrop-blur-lg z-30 ${
            activeCategory ? "bg-white/80" : "bg-white/80"
          }`}
          aria-label="Main navigation"
        >
          {message ? (
            <div
              className="w-full text-center flex justify-center bg-primary text-white h-16 items-center"
              role="alert"
            >
              {message}
            </div>
          ) : null}
          {(currentLocale === "sk" ||
            currentLocale === "cs" ||
            currentLocale === "cz") && (
            <nav
              className="lg:flex flex-row max-w-screen-2xl px-4 gap-12 mx-auto justify-start text-lightgray font-semibold pt-4 hidden"
              aria-label="Primary"
            >
              {menuItems.map((item) => (
                <MenuItem
                  key={item.category}
                  name={item.name}
                  category={item.category}
                  soon={item.soon}
                  isNew={item.new}
                  activeCategory={activeCategory}
                  isMobile={false}
                  tsoon={t("čoskoro")}
                  tnew={t("nové")}
                  onClick={handleMenuItemClick}
                />
              ))}
            </nav>
          )}
          <hr className="my-3" />
          <nav
            className="mx-auto flex gap-5 items-center justify-center py-2 lg:px-8"
            aria-label="Global"
          >
            <div className="items-center justify-center">
              <Link to="/" className="w-full max-w-[300px]" aria-label="Home">
                <img
                  className="max-w-[300px]"
                  src={logo_url}
                  alt="Bilionbuy Logo"
                />
              </Link>
            </div>
            <div className="hidden lg:flex w-[1000px] lg:gap-x-12 items-center">
              <Link
                to="/shops/"
                className="flex items-center gap-2 min-w-fit group"
              >
                <HiMiniSquares2X2 className="w-8 h-8 text-lightgray group-hover:text-primary" />
                <div className="flex flex-col text-xs text-lightgray group-hover:text-primary transition-colors">
                  <span className="max-w-[107px]">
                    {t("Partnerské cashback obchody")}
                  </span>
                </div>
              </Link>
              <div className="flex-1" onClick={() => setSearchOpen(true)}>
                <NewSearchBar
                  placeholder={t(
                    "Prehľadávajte produkty, kategórie, obchody, zážitky..."
                  )}
                  onSearchClick={handleSearchClick}
                  value={searchQuery}
                  onChange={handleQueryChange}
                />
              </div>
            </div>
            <div className="lg:flex flex-row gap-2 hidden">
              <LocationSelector tooltip={t("Vyberte krajinu a jazyk")} />
            </div>
            <div className="hidden lg:flex ml-4 lg:justify-items-center lg:gap-8 text-lightgray items-center">
              {isAuthenticated ? (
                <ProfileComponent />
              ) : (
                <>
                  <Link to="/login" className="text-sm">
                    {t("Login")}
                  </Link>
                  <Link
                    to="/register"
                    className="text-white bg-blue px-8 py-2 rounded-3xl"
                  >
                    {t("Register")}
                  </Link>
                </>
              )}
            </div>
          </nav>
        </header>
      </div>

      {/* Mobile Navbar */}
      <div className="block lg:hidden sticky top-0 z-30">
        <header
          className="sticky top-0 w-full backdrop-blur-lg bg-white/80 z-30"
          aria-label="Mobile navigation"
        >
          <nav className="flex items-center justify-between px-4 py-2">
            {/* Left side with Menu Button and Partner Shops Link */}
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
                aria-label="Open main menu"
              >
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <Link to="/shops/" className="flex items-center gap-1">
                <HiMiniSquares2X2 className="w-4 h-4 text-lightgray" />
                <div className="flex flex-col text-[10px] text-lightgray">
                  {/* <span className="max-w-[70px]">
                    {t("Partnerské cashback obchody")}
                  </span> */}
                </div>
              </Link>
            </div>

            {/* Center Logo */}
            <Link to="/" className="flex-shrink-0" aria-label="Home">
              <img src={logo_url} alt="Bilionbuy Logo" className="h-8 w-auto" />
            </Link>

            {/* Right-side Buttons */}
            <div className="flex items-center">
              {isAuthenticated ? (
                <ProfileComponent />
              ) : (
                <>
                  <Link to="/login" className="text-xs text-gray-500 mr-2">
                    {t("Login")}
                  </Link>
                  <Link
                    to="/register"
                    className="text-white bg-blue px-4 py-2 rounded-3xl text-sm"
                  >
                    {t("Register")}
                  </Link>
                </>
              )}
            </div>
          </nav>

          {/* Mobile Search Bar */}
          <div className="px-4 py-2" onClick={() => setSearchOpen(true)}>
            <NewSearchBar
              placeholder={t(
                "Prehľadávajte produkty, kategórie, obchody, zážitky..."
              )}
              onSearchClick={handleSearchClick}
              value={searchQuery}
              onChange={handleQueryChange}
            />
          </div>
        </header>

        {/* Mobile Menu Dialog */}
        <AnimatePresence>
          {mobileMenuOpen && (
            <Dialog
              as="div"
              className="lg:hidden"
              open={mobileMenuOpen}
              onClose={() => setMobileMenuOpen(false)}
            >
              <div className="fixed inset-0 z-10" />
              <motion.div
                variants={slideInVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
                className="fixed inset-y-0 left-0 z-50 w-full h-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
              >
                <div className="flex items-center justify-between">
                  <Link to="/" className="-m-1.5 p-1.5" aria-label="Home">
                    <img
                      className="h-8 w-auto"
                      src={logo_url}
                      alt="Bilionbuy Logo"
                    />
                  </Link>
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                    aria-label="Close menu"
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6 font-semibold">
                      {currentLocale === "sk" &&
                        menuItems.map((item) => (
                          <MenuItem
                            key={item.category}
                            name={item.name}
                            category={item.category}
                            soon={item.soon}
                            isNew={item.new}
                            activeCategory={activeCategory}
                            isMobile={true}
                            tsoon={t("čoskoro")}
                            tnew={t("nové")}
                            onClick={handleMenuItemClick}
                          />
                        ))}

                      {/* Conditionally render Login/Register menu items */}
                      {!isAuthenticated && (
                        <>
                          <Link
                            to="/login"
                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {t("Login")}
                          </Link>
                          <Link
                            to="/register"
                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {t("Register")}
                          </Link>
                        </>
                      )}

                      <div className="flex flex-row items-center">
                        {t("Krajina a jazyk:")}
                        <LocationSelector
                          tooltip={t("Vyberte krajinu a jazyk")}
                          className=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </Dialog>
          )}
        </AnimatePresence>
      </div>

      {/* Global Search Bar */}
      <SearchBar
        isOpen={searchOpen}
        closeSearch={handleSearchClose}
        initialQuery={searchQuery}
        onQueryChange={handleQueryChange}
        className="w-screen h-screen z-[9999]"
      />

      {/* Product Categories Section */}
      <div ref={productCategoriesRef}>
        {activeCategory && <ProductCategories category={activeCategory} />}
      </div>
    </>
  );
}
