import React from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import FilteredItems from "../components/FilteredItems";
export default function ViewMoreProducts() {
  let { id } = useParams();
  const [searchOpen, setSearchOpen] = useState(false);

  return (
    <>
      <div className="sticky top-0 z-50">
        <BasicNavbar setSearchOpen={setSearchOpen} />
      </div>
      <div>
        <div className="flex justify-center py-24">
          <h1 className="text-5xl font-bold text-center mt-10 bg-gradient-to-r from-[#3769EF] to-[#FD60A9] bg-clip-text text-transparent">
            Výsledky vyhľadávania pre: {id}
          </h1>
        </div>
        <FilteredItems id={id} visible={true} fetchCall={"get-more-products"} />
      </div>
      <Footer />
    </>
  );
}
