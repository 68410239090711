import { Button, Image, Link } from "@nextui-org/react";
import api from "../services/api";
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ProductReviews from "./ProductReviews";
import { useLeaveModal } from "./LeaveModalContext";
import MoreProductInfo from "./MoreProductInfo";
import { useTranslation } from "react-i18next";
import OfferCard from "./OfferCard";

function formatNumber(value, precision = 5) {
  const formatted = parseFloat(value).toPrecision(precision);
  return parseFloat(formatted);
}

const registerClick = async (linkId) => {
  const userToken = localStorage.getItem("jwt");
  const response = await api.post("register-click/", { linkId, userToken });
  console.log("Click registered:", response.data);
};

const fetchProductShops = async (productId) => {
  const url = `get-shop-links/${productId}/`;
  const response = await api.get(url);
  return response.data;
};

export default function ShopList({
  id,
  selector,
  setSelector,
  shopOffers,
  isLoading,
}) {
  const { t } = useTranslation();
  const { openModal } = useLeaveModal();
  const [productShops, setProductShops] = useState(shopOffers);
  const [selectedKey, setSelectedKey] = useState("all");

  const [reviews, setReviews] = useState([]);
  const [advices, setAdvices] = useState([]);
  const [videos, setVideos] = useState([]); // State for videos
  const [hasVideos, setHasVideos] = useState(false); // New state to check if videos exist
  const [foreignShops, setForeignShops] = useState([]);

  // Add new state for view type
  const [viewType, setViewType] = useState(() => {
    const savedViewType = localStorage.getItem("preferredViewType");
    return savedViewType || "grid"; // Default to grid if no preference saved
  });

  // Add effect to save viewType changes to localStorage
  useEffect(() => {
    localStorage.setItem("preferredViewType", viewType);
  }, [viewType]);

  const fetchProductReviews = async (productId) => {
    const url = `get-product-reviews/${productId}/`;
    try {
      const response = await api.get(url);
      setReviews(response.data.reviews);
    } catch (error) {
      console.error("Error fetching product reviews:", error);
      setReviews([]);
    }
  };

  const fetchProductParameters = async (productId) => {
    const url = `get-product-parameters/${productId}/`; // Corrected endpoint
    try {
      const response = await api.get(url);
      setReviews(response.data.parameters); // Assuming parameters are returned here
    } catch (error) {
      console.error("Error fetching product parameters:", error);
      setReviews([]);
    }
  };

  const fetchProductAdvices = async (productId) => {
    const url = `get-product-advices/${productId}/`;
    try {
      const response = await api.get(url);
      setAdvices(response.data.reviews);
    } catch (error) {
      console.error("Error fetching product advices:", error);
      setAdvices([]);
    }
  };

  const fetchProductVideos = async (productId) => {
    // Function to fetch videos
    const url = `get-product-videos/`;
    try {
      const response = await api.get(url, {
        params: { product_id: productId },
      });
      setVideos(response.data);

      // Check if the video data is not null or empty
      if (
        response.data &&
        ((response.data.video_iframe &&
          response.data.video_iframe.length > 0) ||
          (response.data.video_url && response.data.video_url.length > 0))
      ) {
        setHasVideos(true); // Set state to true if videos are available
      } else {
        setHasVideos(false); // Set state to false if no videos are available
      }
    } catch (error) {
      console.error("Error fetching product videos:", error);
      setVideos([]);
      setHasVideos(false); // Set state to false if an error occurs
    }
  };

  // Modify the fetchForeignListings function to use the API
  const fetchForeignListings = async (productId) => {
    const url = `get-foreign-listings/${productId}/`;
    try {
      const response = await api.get(url);
      setForeignShops(response.data);
    } catch (error) {
      console.error("Error fetching foreign listings:", error);
      setForeignShops([]);
    }
  };

  const handleSelectorChange = (name) => {
    setSelector(name);
    if (name !== "price") {
      setSelectedKey("");
    }
  };

  const normalizePrice = (price) =>
    parseFloat(price?.replace(",", ".").replace(/[^\d.]/g, ""));

  const sortAll = (shops) => {
    const sortedShops = [...shops].sort((a, b) => {
      // First, sort by presence of cashback
      if (a.computed_cashback && !b.computed_cashback) return -1;
      if (!a.computed_cashback && b.computed_cashback) return 1;

      // If both have cashback or both don't, sort by price
      return normalizePrice(a.price_vat) - normalizePrice(b.price_vat);
    });
    setProductShops(sortedShops);
    setSelectedKey("all");
  };

  const sortByPrice = () => {
    const sortedProducts = [...productShops].sort(
      (a, b) => normalizePrice(a.price_vat) - normalizePrice(b.price_vat)
    );
    setProductShops(sortedProducts);
    setSelectedKey("price");
  };

  const sortByCashback = () => {
    const sortedProducts = [...productShops].sort((a, b) => {
      const priceA = parseFloat(a.price_vat.replace(/[^\d.]/g, ""));
      const priceB = parseFloat(b.price_vat.replace(/[^\d.]/g, ""));

      const cashbackPercentA = a.computed_cashback
        ? parseFloat(a.computed_cashback)
        : 0;
      const cashbackPercentB = b.computed_cashback
        ? parseFloat(b.computed_cashback)
        : 0;

      const cashbackAmountA = priceA * cashbackPercentA;
      const cashbackAmountB = priceB * cashbackPercentB;

      // Sort by highest cashback amount
      return cashbackAmountB - cashbackAmountA;
    });
    setProductShops(sortedProducts);
    setSelectedKey("cashback");
  };

  const sortByPriceAndCashback = () => {
    const sortedProducts = [...productShops].sort((a, b) => {
      const priceA = parseFloat(a.price_vat.replace(/[^\d.]/g, ""));
      const priceB = parseFloat(b.price_vat.replace(/[^\d.]/g, ""));

      const cashbackPercentA = a.computed_cashback
        ? parseFloat(a.computed_cashback)
        : 0;
      const cashbackPercentB = b.computed_cashback
        ? parseFloat(b.computed_cashback)
        : 0;

      const cashbackAmountA = priceA - priceA * cashbackPercentA;
      const cashbackAmountB = priceB - priceB * cashbackPercentB;

      // Sort by highest cashback amount
      return cashbackAmountA - cashbackAmountB;
    });

    setProductShops(sortedProducts);
    setSelectedKey("overall");
  };

  // Modify useEffect to set data and sort initially
  useEffect(() => {
    (async () => {
      const data = await fetchProductShops(id);
      sortAll(data);
      fetchProductReviews(id);
      fetchProductAdvices(id);
      fetchProductParameters(id);
      fetchProductVideos(id);
      fetchForeignListings(id);
    })();
  }, [id]);

  // Function to resize all video iframes
  const resizeIframes = () => {
    const iframes = document.querySelectorAll(".video-iframe-container iframe");

    if (iframes.length === 0) return; // Return early if no iframes are found

    iframes.forEach((iframe) => {
      iframe.onload = () => {
        // Ensure the iframe is fully loaded before applying styles
        const aspectRatio = 16 / 9; // Define your aspect ratio
        iframe.style.width = "100%"; // Set the width to 100%
        iframe.style.border = "0"; // Removes border

        const width = iframe.clientWidth; // Get the current width of the iframe
        iframe.style.height = `${width / aspectRatio}px`; // Set the height based on aspect ratio
      };

      // If the iframe is already loaded, apply styles immediately
      if (iframe.contentWindow?.document.readyState === "complete") {
        iframe.onload();
      }
    });
  };

  useEffect(() => {
    if (selector === "video") {
      resizeIframes(); // Call resizeIframes when the "Video" tab is selected
    }
  }, [selector, videos]);

  useEffect(() => {
    window.addEventListener("resize", resizeIframes); // Add event listener for resize
    return () => window.removeEventListener("resize", resizeIframes); // Cleanup on unmount
  }, []);

  const getDeliveryText = (deliveryDate) => {
    if (!deliveryDate && deliveryDate !== 0) {
      return "info v obchode";
    }

    const date = parseInt(deliveryDate);

    if (date === 0) {
      return "skladom";
    } else if (date >= 1 && date <= 3) {
      return "do 3 dní";
    } else if (date >= 4 && date <= 7) {
      return "do týždňa";
    } else if (date >= 8 && date <= 14) {
      return "do 2 týždňov";
    } else if (date >= 15 && date <= 30) {
      return "do mesiaca";
    } else if (date >= 31) {
      return "viac ako mesiac";
    } else {
      return "info v obchode";
    }
  };

  const handleRedirectClick = (shopUrl, shopId, shopName) => {
    const userToken = localStorage.getItem("jwt");
    const isAuthenticated = !!userToken; // Determine authentication status

    if (isAuthenticated) {
      registerClick(shopId);
    }

    openModal(
      shopName,
      () => {
        const link = document.createElement("a");
        link.href = shopUrl;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      isAuthenticated
    ); // Pass isAuthenticated here
  };

  // Update productShops when shopOffers prop changes
  useEffect(() => {
    setProductShops(shopOffers);
  }, [shopOffers]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, delay: 0.5, ease: "easeOut" }}
    >
      {/* Top Row: Component Navigation */}
      <nav
        className="mx-auto max-w-7xl flex gap-10 py-4 font-semibold text-lightgray md:text-base text-sm md:justify-start justify-center px-4 xl:px-0 "
        id="comparison"
        aria-label="Product shop comparison options"
      >
        <button
          onClick={() => handleSelectorChange("price")}
          className={`${
            selector === "price"
              ? "text-blue underline underline-offset-8"
              : "hover:text-blue"
          } cursor-pointer`}
        >
          {t("Porovnanie cien")}
        </button>

        <button
          onClick={() => handleSelectorChange("params")}
          className={`${
            selector === "params"
              ? "text-blue underline underline-offset-8"
              : "hover:text-blue"
          } cursor-pointer`}
        >
          {t("Popis produktu")}
        </button>

        {/* Conditionally Render New Video Tab */}
        {hasVideos && (
          <button
            onClick={() => handleSelectorChange("video")}
            className={`${
              selector === "video"
                ? "text-blue underline underline-offset-8"
                : "hover:text-blue"
            } cursor-pointer`}
          >
            {t("Video")}
          </button>
        )}

        <button
          onClick={() => handleSelectorChange("reviews")}
          className={`${
            selector === "reviews"
              ? "text-blue underline underline-offset-8"
              : "hover:text-blue"
          } cursor-pointer`}
        >
          {t("Recenzie")}
        </button>
      </nav>

      {/* Shops List Section */}
      <section
        className="bg-[#F1F3F5] pt-12"
        id="offers-list"
        aria-labelledby="shop-list"
      >
        <div className="mx-auto max-w-screen-xl px-4 xl:px-0">
          {/* Add content for new Video tab */}
          {selector === "video" && (
            <div className="text-center flex flex-col gap-8 ">
              {videos?.video_iframe || videos?.video_url ? (
                <>
                  {videos?.video_iframe &&
                    videos.video_iframe.map((video, index) => (
                      <div
                        key={index}
                        className="w-full video-iframe-container shadow-lg"
                      >
                        <div
                          className="relative overflow-hidden"
                          dangerouslySetInnerHTML={{
                            __html: video,
                          }}
                        ></div>
                      </div>
                    ))}

                  {videos?.video_url && <div>{videos.video_url}</div>}
                </>
              ) : (
                <div>No video data</div>
              )}
            </div>
          )}

          {/* Display Shops List when "Porovnanie cien" is selected */}
          {selector === "price" && (
            <>
              {/* Sorting Options */}
              <div className="mx-auto max-w-screen-xl flex flex-col md:flex-row md:gap-4 text-sm text-lightgray font-semibold mb-4">
                {/* Sorting Options for Larger Screens */}
                <div className="hidden md:flex flex-row gap-4">
                  <span>{t("Zoradiť")}:</span>
                  <button
                    onClick={() => sortAll(productShops)}
                    className={`${
                      selectedKey === "all" ? "text-blue" : "hover:text-blue"
                    }`}
                  >
                    {t("Všetky")}
                  </button>
                  <button
                    onClick={sortByPrice}
                    className={`${
                      selectedKey === "price" ? "text-blue" : "hover:text-blue"
                    }`}
                  >
                    {t("Najlacnejšie")}
                  </button>
                  <button
                    onClick={sortByCashback}
                    className={`${
                      selectedKey === "cashback"
                        ? "text-blue"
                        : "hover:text-blue"
                    }`}
                  >
                    {t("Najväčší cashback")}
                  </button>
                  <button
                    onClick={sortByPriceAndCashback}
                    className={`${
                      selectedKey === "overall"
                        ? "text-blue"
                        : "hover:text-blue"
                    }`}
                  >
                    {t("Najvýhodnejšie s cashbackom")}
                  </button>
                </div>

                {/* Sorting Options for Mobile Screens */}
                <div className="md:hidden flex items-center px-4">
                  <span className="mr-2">{t("Zoradiť")}:</span>
                  <select
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      if (selectedValue === "all") sortAll(productShops);
                      else if (selectedValue === "price") sortByPrice();
                      else if (selectedValue === "cashback") sortByCashback();
                      else if (selectedValue === "overall")
                        sortByPriceAndCashback();
                    }}
                    className="border border-gray-300 p-4 bg-white rounded-lg"
                    value={selectedKey}
                  >
                    <option value="all">{t("Všetky")}</option>
                    <option value="price">{t("Najlacnejšie")}</option>
                    <option value="cashback">{t("Najväčší cashback")}</option>
                    <option value="overall">
                      {t("Najvýhodnejšie s cashbackom")}
                    </option>
                  </select>
                </div>
              </div>

              {/* Add after the sorting options div and before the shops list */}
              <div className="flex justify-end mb-4">
                <div className="flex gap-2 bg-white p-1 rounded-lg">
                  <button
                    onClick={() => setViewType("grid")}
                    className={`p-2 rounded ${
                      viewType === "grid"
                        ? "bg-blue text-white"
                        : "text-gray-600 hover:bg-gray-100"
                    }`}
                    aria-label="Grid view"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => setViewType("list")}
                    className={`p-2 rounded ${
                      viewType === "list"
                        ? "bg-blue text-white"
                        : "text-gray-600 hover:bg-gray-100"
                    }`}
                    aria-label="List view"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Shops List */}
              <h2 className="text-2xl font-bold mt-12 mb-6">
                {t("Ďalšie odporúčané ponuky")}
              </h2>
              {productShops.length > 0 ? (
                viewType === "grid" ? (
                  <motion.div
                    layout
                    className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
                  >
                    <AnimatePresence>
                      {productShops.map((shop, index) => (
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.3 }}
                          key={index}
                        >
                          <OfferCard
                            shop={shop}
                            onRegisterClick={(finalUrl, shopId, shopName) =>
                              handleRedirectClick(finalUrl, shopId, shopName)
                            }
                          />
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </motion.div>
                ) : (
                  <motion.ul layout>
                    <AnimatePresence>
                      {productShops.map((shop, index) => (
                        <motion.li
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.3 }}
                          key={index}
                          className="relative flex md:flex-row flex-col justify-between gap-x-6 py-5 bg-white hover:bg-gray-50 rounded-sm md:h-[166px] px-5 my-8 shadow-md border-1 hover:shadow-lg w-full mx-auto md:w-auto "
                          itemScope
                          itemType="https://schema.org/Offer"
                        >
                          <Link
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRedirectClick(
                                shop.final_url,
                                shop.id,
                                shop.shop_name
                              );
                            }}
                            className="flex items-center hover:opacity-80 transition-opacity"
                          >
                            <img
                              src={shop.shop_image}
                              alt={`${shop.shop_name} logo`}
                              className="w-36 max-w-36 max-h-24 p-4"
                            />
                            <div className="text-blue font-bold underline underline-offset-4 md:block hidden">
                              {shop.shop_name}
                            </div>
                          </Link>
                          <div className="max-w-[400px] flex flex-1 flex-wrap gap-y-0 py-2 md:py-0 gap-x-2 items-center justify-start text-xs font-bold h-[82px] my-auto">
                            {shop.computed_points && (
                              <button className="text-white bg-primary px-2 rounded-3xl py-1">
                                {t("Body")}:{" "}
                                {formatNumber(shop.computed_points)}
                              </button>
                            )}
                            {shop.stock && shop.stock !== "0" && (
                              <button className="text-white bg-green px-2 rounded-3xl py-1">
                                {t("Na sklade")}
                                {shop.stock_count
                                  ? `: ${shop.stock_count}`
                                  : ""}
                              </button>
                            )}
                            {shop.delivery_date !== undefined && (
                              <button className="text-white bg-orange px-2 rounded-3xl py-1">
                                {t("Dodanie")}:{" "}
                                {t(getDeliveryText(shop.delivery_date))}
                              </button>
                            )}
                            {shop.delivery && (
                              <button className="text-white bg-purple px-2 rounded-3xl py-1">
                                {t("Doprava")}:{" "}
                                {typeof shop.delivery === "object"
                                  ? (
                                      shop.delivery.DELIVERY_PRICE ||
                                      shop.delivery["g:price"] ||
                                      "0"
                                    )
                                      .toString()
                                      .replace(".", ",")
                                  : shop.delivery}
                              </button>
                            )}
                            {shop.gift && (
                              <button className="text-white bg-yellow-500 px-2 rounded-3xl py-1">
                                {t("Darček")}
                              </button>
                            )}
                            {shop.extended_warranty && (
                              <button className="text-white bg-indigo-500 px-2 rounded-3xl py-1">
                                {t("Predĺžená záruka")}
                              </button>
                            )}
                            {shop.special_service && (
                              <button className="text-white bg-pink px-2 rounded-3xl py-1">
                                {t("Špeciálna služba")}
                              </button>
                            )}
                            {shop.sales_voucher && (
                              <button className="text-white bg-green px-2 rounded-3xl py-1">
                                {t("Zľavový kupón")}
                              </button>
                            )}
                          </div>
                          <div className="md:mr-24 md:flex-col justify-center md:justify-start flex-wrap my-auto gap-4">
                            <div className="text-blue md:flex hidden font-bold text-right text-xl">
                              &nbsp;
                              {shop.price_vat
                                ? shop.price_vat?.replace(".", ",")
                                : "N/A"}{" "}
                              {shop.currency
                                ? shop.currency?.replace("EUR", "€")
                                : ""}
                            </div>
                            {shop.computed_cashback ? (
                              <div className="md:flex hidden shadow-lg absolute -right-10 -top-5 flex-col items-center justify-center w-32 h-32 bg-blue text-white rounded-full">
                                <div className="text-xl font-bold">
                                  {(
                                    shop.computed_cashback *
                                    normalizePrice(shop.price_vat)
                                  ).toFixed(2)}{" "}
                                  {shop.currency
                                    ? shop.currency?.replace("EUR", "€")
                                    : ""}
                                </div>
                                <div className="text-sm">{t("cashback")}</div>
                              </div>
                            ) : (
                              <div className="md:flex hidden shadow-lg absolute -right-10 -top-5 flex-col items-center justify-center w-32 h-32 bg-gray-400 text-white rounded-full">
                                <div
                                  className="text-sm text-center uppercase font-semibold"
                                  dangerouslySetInnerHTML={{
                                    __html: t("bez <br /> cashbacku"),
                                  }}
                                ></div>
                              </div>
                            )}
                            <Button
                              as={Link}
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleRedirectClick(
                                  shop.final_url,
                                  shop.id,
                                  shop.shop_name
                                );
                              }}
                              className="bg-blue text-white rounded-3xl px-10 text-lg font-semibold md:w-auto w-full"
                            >
                              {t("Do obchodu")} &rarr;
                            </Button>
                          </div>
                        </motion.li>
                      ))}
                    </AnimatePresence>
                  </motion.ul>
                )
              ) : (
                <div className="text-2xl text-lightgray font-semibold text-center">
                  {t("Načítavam obchody...")}
                </div>
              )}

              {/* Foreign Shops List */}
              {foreignShops.length > 0 && (
                <>
                  <h2 className="text-2xl font-bold mt-12 mb-6">
                    {t("Zo zahraničných trhov")}
                  </h2>
                  {viewType === "grid" ? (
                    <motion.div
                      layout
                      className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
                    >
                      <AnimatePresence>
                        {foreignShops.map((shop, index) => (
                          <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.3 }}
                            key={`foreign-${index}`}
                          >
                            <OfferCard
                              shop={shop}
                              onRegisterClick={(finalUrl, shopId, shopName) =>
                                handleRedirectClick(finalUrl, shopId, shopName)
                              }
                            />
                          </motion.div>
                        ))}
                      </AnimatePresence>
                    </motion.div>
                  ) : (
                    <motion.ul layout>
                      <AnimatePresence>
                        {foreignShops.map((shop, index) => (
                          <motion.li
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.3 }}
                            key={`foreign-${index}`}
                            className="relative flex md:flex-row flex-col justify-between gap-x-6 py-5 bg-white hover:bg-gray-50 rounded-sm md:h-[166px] px-5 my-8 shadow-md border-1 hover:shadow-lg w-full mx-auto md:w-auto "
                            itemScope
                            itemType="https://schema.org/Offer"
                          >
                            <Link
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleRedirectClick(
                                  shop.final_url,
                                  shop.id,
                                  shop.shop_name
                                );
                              }}
                              className="flex items-center hover:opacity-80 transition-opacity"
                            >
                              <img
                                src={shop.shop_image}
                                alt={`${shop.shop_name} logo`}
                                className="w-36 max-w-36 max-h-24 p-4"
                              />
                              <div className="text-blue font-bold underline underline-offset-4 md:block hidden">
                                {shop.shop_name}
                              </div>
                            </Link>
                            <div className="max-w-[400px] flex flex-1 flex-wrap gap-y-0 py-2 md:py-0 gap-x-2 items-center justify-start text-xs font-bold h-[82px] my-auto">
                              {shop.computed_points ? (
                                <button className="text-white bg-primary px-2 rounded-3xl py-1">
                                  {t("Body")}:{" "}
                                  {formatNumber(shop.computed_points)}{" "}
                                </button>
                              ) : null}
                              {shop.stock ? (
                                <button className="text-white bg-green px-2 rounded-3xl py-1">
                                  {t("Na sklade")}
                                </button>
                              ) : null}
                              {/* Add more details as needed */}
                            </div>
                            <div className="md:mr-24 md:flex-col justify-center md:justify-start flex-wrap my-auto gap-4">
                              <div className="text-blue md:flex hidden font-bold text-right text-xl">
                                &nbsp;
                                {shop.price_vat
                                  ? shop.price_vat?.replace(".", ",")
                                  : "N/A"}{" "}
                                {shop.currency
                                  ? shop.currency?.replace("EUR", "€")
                                  : ""}
                              </div>
                              {shop.computed_cashback ? (
                                <div className="md:flex hidden shadow-lg absolute -right-10 -top-5 flex-col items-center justify-center w-32 h-32 bg-blue text-white rounded-full">
                                  <div className="text-xl font-bold">
                                    {(
                                      shop.computed_cashback *
                                      normalizePrice(shop.price_vat)
                                    ).toFixed(2)}{" "}
                                    {shop.currency
                                      ? shop.currency?.replace("EUR", "€")
                                      : ""}
                                  </div>
                                  <div className="text-sm">cashback</div>
                                </div>
                              ) : (
                                <div className="md:flex hidden shadow-lg absolute -right-10 -top-5 flex-col items-center justify-center w-32 h-32 bg-gray-400 text-white rounded-full">
                                  <div className="text-sm text-center uppercase font-semibold">
                                    bez <br /> cashbacku
                                  </div>
                                </div>
                              )}
                              <Button
                                as={Link}
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleRedirectClick(
                                    shop.final_url,
                                    shop.id,
                                    shop.shop_name
                                  );
                                }}
                                className="bg-blue text-white rounded-3xl px-10 text-lg font-semibold md:w-auto w-full"
                              >
                                {t("Do obchodu")} &rarr;
                              </Button>
                            </div>
                          </motion.li>
                        ))}
                      </AnimatePresence>
                    </motion.ul>
                  )}
                </>
              )}
            </>
          )}

          {/* Additional Component Displays */}
          <div className="mx-auto max-w-7xl">
            {selector === "reviews" ? (
              <ProductReviews productId={id} t={t} />
            ) : null}
          </div>
          <div className="mx-auto max-w-7xl">
            {selector === "params" ? (
              <MoreProductInfo productId={id} t={t} />
            ) : null}
          </div>
          <div className="mx-auto max-w-7xl sm:px-6 md:px-8">
            {selector === "advice" ? (
              reviews.length > 0 ? (
                <div>Poradňa</div>
              ) : (
                <div className="text-2xl text-lightgray font-semibold text-center">
                  {t("Pre daný produkt neboli nájdené žiadne záznamy")}
                </div>
              )
            ) : null}
          </div>
        </div>
      </section>
    </motion.div>
  );
}
