import React from "react";
import { Button, Link, Tooltip } from "@nextui-org/react";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";

const OfferCard = ({ shop, onRegisterClick, compact = false }) => {
  const { t } = useTranslation();

  const getDeliveryText = (deliveryDate) => {
    if (deliveryDate === 0) {
      return "skladom";
    } else if (deliveryDate >= 1 && deliveryDate <= 3) {
      return "do 3 dní";
    } else if (deliveryDate >= 4 && deliveryDate <= 7) {
      return "do týždňa";
    } else if (deliveryDate >= 8 && deliveryDate <= 14) {
      return "do 2 týždňov";
    } else if (deliveryDate >= 15 && deliveryDate <= 30) {
      return "do mesiaca";
    } else if (deliveryDate >= 31) {
      return "viac ako mesiac";
    } else {
      return "info v obchode";
    }
  };

  return (
    <div
      className={`bg-white rounded-lg shadow-md p-4 h-full ${
        compact ? "border" : ""
      }`}
    >
      {compact ? (
        // Compact layout for hero section
        <div className="flex items-center space-x-4">
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onRegisterClick(shop.url, shop.id, shop.shop_name);
            }}
            className="flex items-center space-x-4 flex-1"
          >
            <img
              src={shop.shop_image}
              alt={shop.shop_name}
              className="w-16 h-16 object-contain"
            />
            <div className="flex-1">
              <div className="font-semibold">{shop.shop_name}</div>
              <div className="text-lg font-bold">{shop.price_vat}</div>
              {shop.computed_cashback && (
                <div className="text-success text-sm">
                  Cashback{" "}
                  {(
                    shop.computed_cashback *
                    parseFloat(shop.price_vat.replace(/[^\d.]/g, ""))
                  ).toFixed(2)}{" "}
                  {shop.currency?.replace("EUR", "€")}
                </div>
              )}
            </div>
          </Link>
          <Button
            color="primary"
            size="sm"
            onClick={() => onRegisterClick(shop.url, shop.id, shop.shop_name)}
          >
            {t("Do obchodu")}
          </Button>
        </div>
      ) : (
        // Modified full card layout
        <div className="h-full flex flex-col p-6">
          {/* Top section with fixed content */}
          <div className="flex-1">
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onRegisterClick(shop.final_url, shop.id, shop.shop_name);
              }}
              className="flex flex-col mb-4 hover:opacity-80 transition-opacity"
            >
              <img
                src={shop.shop_image}
                alt={`${shop.shop_name} logo`}
                className="w-24 h-24 object-contain mr-4"
              />
              <h3 className="text-lg font-bold">{shop.shop_name}</h3>
            </Link>
            <div className="mb-4">
              <p className="text-2xl font-bold text-blue">
                <div>
                  {shop.price_vat?.replace(".", ",")}{" "}
                  {shop.currency?.replace("EUR", "€")}
                </div>
              </p>
            </div>
            <div className="flex flex-wrap gap-2">
              {/* Cashback tag moved to first position */}
              <span
                className={`text-white ${shop.computed_cashback > 0 ? "bg-primary" : "bg-gray-500"} font-semibold px-2 py-1 rounded-full text-sm`}
              >
                {shop.computed_cashback > 0 ? (
                  <>
                    <span>cashback </span>
                    <span>
                      {(
                        shop.computed_cashback *
                        parseFloat(shop.price_vat.replace(/[^\d.]/g, ""))
                      ).toFixed(2)}{" "}
                      {shop.currency?.replace("EUR", "€")}
                    </span>
                  </>
                ) : (
                  t("BEZ CASHBACKU")
                )}
              </span>
              {/* Rest of the tags */}
              {shop.computed_points > 0 && (
                <span className="bg-primary text-white font-semibold px-2 py-1 rounded-full text-sm">
                  {t("Body")}: {Number(shop.computed_points).toFixed(2)}
                </span>
              )}
              {shop.stock && shop.stock !== "0" && (
                <span className="bg-green text-white font-semibold px-2 py-1 rounded-full text-sm">
                  {t("Na sklade")}
                  {shop.stock_count ? `: ${shop.stock_count}` : ""}
                </span>
              )}
              {shop.delivery_date !== undefined && (
                <span className="bg-orange text-white font-semibold px-2 py-1 rounded-xl text-sm">
                  {t("Dodanie")}: {t(getDeliveryText(shop.delivery_date))}
                </span>
              )}
              {shop.delivery && (
                <span className="bg-purple text-white font-semibold px-2 py-1 rounded-full text-sm">
                  {t("Doprava")}:{" "}
                  {typeof shop.delivery === "object"
                    ? (
                        shop.delivery.DELIVERY_PRICE ||
                        shop.delivery["g:price"] ||
                        "0"
                      )
                        .toString()
                        .replace(".", ",")
                    : shop.delivery}
                </span>
              )}
              {shop.gift && (
                <span className="bg-yellow-500 text-white font-semibold px-2 py-1 rounded-full text-sm">
                  {t("Darček")}
                </span>
              )}
              {shop.extended_warranty && (
                <span className="bg-indigo-500 text-white font-semibold px-2 py-1 rounded-full text-sm">
                  {t("Predĺžená záruka")}
                </span>
              )}
              {shop.special_service && (
                <span className="bg-pink text-white font-semibold px-2 py-1 rounded-full text-sm">
                  {t("Špeciálna služba")}
                </span>
              )}
              {shop.sales_voucher && (
                <span className="bg-green text-white font-semibold px-2 py-1 rounded-full text-sm">
                  {t("Zľavový kupón")}
                </span>
              )}
            </div>
          </div>

          {/* Bottom section with button - always at bottom */}
          <div className="mt-auto pt-4">
            <Button
              as={Link}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onRegisterClick(shop.final_url, shop.id, shop.shop_name);
              }}
              className="bg-blue text-white rounded-full px-8 py-2 w-full"
            >
              {t("Do obchodu")} &rarr;
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OfferCard;
