import { useParams } from "react-router-dom";
import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Image, Button, Link, Skeleton, Spinner } from "@nextui-org/react";
import { Helmet } from "react-helmet-async";
import api from "../services/api";
import { useAdultContent } from "../providers/AdultContentProvider";
import { useTranslation } from "react-i18next";

// Lazy Load Components
const BasicNavbar = lazy(() => import("../components/BasicNavbar"));
const FilteredItems = lazy(() => import("../components/FilteredItems"));

const Category = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  const [categoryData, setCategoryData] = useState(null);
  const [topProducts, setTopProducts] = useState([]);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toploading, setTopLoading] = useState(false);
  const { requestAgeConfirmation } = useAdultContent();

  const fetchSubcategories = useCallback(
    async (controller) => {
      try {
        const response = await api.get("get-subcategories", {
          params: { category: id },
          signal: controller.signal,
        });

        // Check if category is adult content
        if (response.data.adult) {
          requestAgeConfirmation(() => {
            setCategoryData(response.data);
            setVisibleFilter(response.data.hasFilter);
          });
        } else {
          setCategoryData(response.data);
          setVisibleFilter(response.data.hasFilter);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching data: ", error);
        }
      }
    },
    [id, requestAgeConfirmation]
  );

  const fetchTopProducts = useCallback(
    async (controller) => {
      try {
        setTopLoading(true);
        const response = await api.get("get-top-products", {
          params: { category: id },
          signal: controller.signal,
        });
        setTopProducts(response.data);
        setTopLoading(false);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching data: ", error);
        }
      }
    },
    [id]
  );

  useEffect(() => {
    const controller = new AbortController(); // Create a new AbortController
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          fetchSubcategories(controller),
          fetchTopProducts(controller),
        ]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup function to abort fetch on unmount
    return () => controller.abort();
  }, [id, fetchSubcategories, fetchTopProducts]);

  if (loading) {
    return (
      <div className="max-w-screen-2xl mx-auto">
        <Skeleton width="100%" height="80px" className="mb-8" />
        <Skeleton width="100%" height="500px" className="mb-8" />
        <Skeleton width="100%" height="500px" className="mb-8" />
        <Skeleton width="100%" height="500px" className="mb-8" />
      </div>
    );
  }

  if (!categoryData) {
    return <div>{t("No data available for this category")}</div>;
  }

  const { subcategories } = categoryData;

  return (
    <>
      {/* SEO Optimization with Helmet */}
      <Helmet>
        <title>{`${id} - Product Category`}</title>
        <meta
          name="description"
          content={`Explore a wide range of products under the ${id} category. Discover top products, subcategories, and the best deals available.`}
        />
        <meta
          name="keywords"
          content={`products, ${id}, buy ${id} products, ${id} deals, ${id} discounts, top ${id} products`}
        />
      </Helmet>

      {/* Basic Navbar */}
      <Suspense fallback={<Skeleton width="100%" height="60px" />}>
        <BasicNavbar />
      </Suspense>

      <div className="mx-auto overflow-x-hidden">
        {/* Category Title and Top Products */}
        <header className="flex items-center justify-center py-4 px-4 xl:px-0">
          <div className="text-white font-bold bg-primary text-4xl px-8 py-4 rounded-xl w-full shadow-lg text-center lg:w-lg lg:max-w-lg">
            <h1>{id}</h1>
          </div>
        </header>
        {toploading ? (
          <Spinner />
        ) : topProducts?.length > 5 ? (
          <section
            aria-labelledby="top-products-section"
            className="max-w-7xl mx-auto"
          >
            <div className="grid xl:grid-rows-2 gap-6 xl:px-0 px-4">
              <div className=" flex-row gap-6 xl:flex hidden">
                {/* Left Column - Top Products */}
                <article
                  className="flex flex-col gap-6"
                  aria-label="Top Products"
                >
                  <h2 className="sr-only">Top Products</h2>
                  <div className=" grid grid-cols-2 justify-center gap-8  w-[500px] h-full">
                    {topProducts.slice(3).map((product) => (
                      <a
                        key={product.id}
                        href={"/view-product/" + product?.slug}
                        className="group/item hover:cursor-pointer rounded-lg transition ease-in-out delay-150"
                      >
                        <div className="w-full border-1 shadow-lg rounded-lg flex flex-col items-center">
                          <div className="inline-block overflow-hidden bg-white rounded-xl shadow-lg">
                            <Image
                              src={product.product_image_url}
                              className="block h-[94px] w-[240px] shadow-lg object-cover group-hover/item:scale-100 transition ease-in-out duration-300 scale-150"
                              alt={product.product_name}
                            />
                          </div>
                          <div className="text-center px-4 py-4 font-semibold grid place-items-center h-20">
                            <span className="line-clamp-2">
                              {product.product_name}
                            </span>
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </article>

                {/* Right Column - Featured Product */}
                <aside
                  className="border-1 shadow-lg rounded-lg h-[410px]"
                  aria-label="Featured Product"
                >
                  <a
                    href={"/view-product/" + topProducts[1]?.slug}
                    className="relative group/item hover:cursor-pointer rounded-lg transition ease-in-out delay-150 flex flex-col"
                  >
                    <div className="inline-block overflow-hidden shadow-lg rounded-xl">
                      <img
                        src={topProducts[1]?.product_image_url}
                        alt={topProducts[1]?.product_name}
                        className="w-full h-[250px] object-contain shadow-lg rounded-lg group-hover/item:scale-100 transition ease-in-out duration-300 scale-150"
                      />
                    </div>
                    <div className="font-bold text-xl px-8 pt-4 line-clamp-2">
                      {topProducts[1] ? topProducts[1].product_name : ""}
                    </div>
                    <div className="px-8 py-4">
                      <div className="line-clamp-2">
                        {topProducts[1]
                          ? topProducts[1].product_description
                          : ""}
                      </div>
                    </div>
                  </a>
                </aside>
              </div>

              {/* Main Featured Product */}
              <article
                className="border-1 shadow-lg rounded-lg w-full"
                aria-label="Main Featured Product"
              >
                <a
                  href={"/view-product/" + topProducts[0]?.slug}
                  className="group/item hover:cursor-pointer rounded-lg transition ease-in-out delay-150"
                >
                  <div className="inline-block object-contain overflow-hidden shadow-lg rounded-xl w-full">
                    <img
                      src={
                        topProducts[0] ? topProducts[0].product_image_url : ""
                      }
                      alt={topProducts[0]?.product_name}
                      className="w-full h-[300px] object-contain shadow-lg rounded-lg group-hover/item:scale-100 transition ease-in-out duration-300 scale-150"
                    />
                  </div>
                  <div className="font-bold text-xl px-8 pt-4">
                    {topProducts[0] ? topProducts[0].product_name : ""}
                  </div>
                  <div className="px-8 py-4">
                    <div className="line-clamp-2 max-w-[1100px]">
                      {topProducts[0] ? topProducts[0].product_description : ""}
                    </div>
                  </div>
                </a>
              </article>
            </div>
          </section>
        ) : null}
        {/* Subcategories */}
        <section
          className="flex flex-wrap gap-4 justify-center pt-12 px-4 xl:px-0"
          aria-labelledby="subcategories"
        >
          <h2 className="sr-only">Subcategories</h2>
          {Array.isArray(subcategories) && subcategories.length > 0
            ? subcategories.map((subcategory, index) => (
                <a
                  key={index}
                  href={`/category/${subcategory}`}
                  className="text-black border-1 shadow-lg px-8 py-2 rounded-3xl"
                >
                  {subcategory}
                </a>
              ))
            : null}
        </section>
        {/* Filtered Items */}
        <section
          className="max-w-screen-3xl mx-auto"
          aria-labelledby="filtered-items"
        >
          <hr className="my-8" />
          <Suspense fallback={<Skeleton width="100%" height="400px" />}>
            <FilteredItems
              t={t}
              id={id}
              visible={visibleFilter}
              fetchCall={"get-products-by-category"}
            />
          </Suspense>
        </section>
      </div>
    </>
  );
};

export default React.memo(Category);
