import { FaCar } from "react-icons/fa";
import { GiSofa } from "react-icons/gi";
import { TbHorseToy } from "react-icons/tb";
import { IoHammerSharp } from "react-icons/io5";
import { BiSolidFridge } from "react-icons/bi";
import { FaBottleDroplet } from "react-icons/fa6";
import { MdPhoneAndroid } from "react-icons/md";
import { FaBookOpen } from "react-icons/fa";
import { IoGameController } from "react-icons/io5";
import { FaGuitar } from "react-icons/fa";
import { MdToys } from "react-icons/md";
import { BiSolidDog } from "react-icons/bi";
import { FaPencilAlt } from "react-icons/fa";
import { GiDelicatePerfume } from "react-icons/gi";
import { FaTshirt } from "react-icons/fa";
import { MdSportsFootball } from "react-icons/md";
import { GiHealthNormal } from "react-icons/gi";
import { GiWashingMachine } from "react-icons/gi";
import { GiLips } from "react-icons/gi";
import { MdFoodBank } from "react-icons/md";

import { MdOutlineDesignServices } from "react-icons/md";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { FaTv } from "react-icons/fa6";
import { MdOutlineTravelExplore } from "react-icons/md";
import { BsCalendar4Event } from "react-icons/bs";
import { IoMdRefreshCircle } from "react-icons/io";
import { FaComputer } from "react-icons/fa6";
import { MdPhotoCamera } from "react-icons/md";
import { IoAnalyticsOutline } from "react-icons/io5";
import { FiPrinter } from "react-icons/fi";

export const categories = [
  {
    category_name_sk: "Auto-moto",
    category_name_cz: "Auto-moto",
    tag_name: "Auto-moto",
    order: 1,
    icon: FaCar,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/auto-moto.jpg",
    subcategories: [
      {
        category_name_sk: "Náplne a kvapaliny",
        category_name_cz: "Náplně a kapaliny",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Elektromobilita",
        category_name_cz: "Elektromobilita",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Pneumatiky",
        category_name_cz: "Pneumatiky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Všetko pre autá",
        category_name_cz: "Vše pro auta",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Všetko pre motorky",
        category_name_cz: "Vše pro motorky",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Bývanie a doplnky",
    category_name_cz: "Bydlení a doplňky",
    tag_name: "Bývanie a doplnky",
    order: 1,
    icon: GiSofa,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/bytove-doplnky.jpg",
    subcategories: [
      {
        category_name_sk: "Bytové doplnky",
        category_name_cz: "Bytové doplňky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Nábytok",
        category_name_cz: "Nábytek",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Sanita a vybavenie kúpeľní",
        category_name_cz: "Sanita a vybavení koupelen",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Osvetlenie",
        category_name_cz: "Osvětlení",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Bytové dekorácie",
        category_name_cz: "Bytové dekorace",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Bytový textil",
        category_name_cz: "Bytový textil",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Obývacia izba",
        category_name_cz: "Obývací pokoj",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Spálne",
        category_name_cz: "Ložnice",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Chodba",
        category_name_cz: "Chodba",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Domáce potreby a upratovanie",
        category_name_cz: "Domácí potřeby a úklid",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Detský tovar",
    category_name_cz: "Dětské zboží",
    tag_name: "Detský tovar",
    order: 1,
    icon: TbHorseToy,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/detske-veci.jpg",
    subcategories: [
      {
        category_name_sk: "Dojčenské potreby",
        category_name_cz: "Potřeby pro kojící matky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Cestovanie s deťmi",
        category_name_cz: "Cestování s dětmi",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Detská výživa a kŕmenie",
        category_name_cz: "Dětské výživy a krmení",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Prebaľovanie",
        category_name_cz: "Přebíjení",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Kúpanie detí",
        category_name_cz: "Koupení dětí",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Vybavenie detskej izby",
        category_name_cz: "Vybavení dětské izby",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Pre matku",
        category_name_cz: "Pro matku",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Dielňa, stavba, záhrada",
    category_name_cz: "Dílna, stavba, zahrada",
    tag_name: "Dielňa, stavba, záhrada",
    order: 1,
    icon: IoHammerSharp,
    subcategories: [
      {
        category_name_sk: "Záhrada",
        category_name_cz: "Zahrada",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Dielňa",
        category_name_cz: "Dílna",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Domácnosť",
        category_name_cz: "Domácnost",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Bazény a doplnky",
        category_name_cz: "Bazény a doplňky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Stavba",
        category_name_cz: "Stavba",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Kúrenie a ohrev vody",
        category_name_cz: "Kouření a ohřev vody",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Náradie pre dielňu a záhradu",
        category_name_cz: "Nářadí pro dílnu a zahradu",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Domáce a osobné spotrebiče",
    category_name_cz: "Domácí a osobní spotřebiče",
    tag_name: "Domáce a osobné spotrebiče",
    order: 1,
    icon: BiSolidFridge,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/kuchyna-elektro.jpg",
    subcategories: [
      {
        category_name_sk: "Kuchynské spotrebiče",
        category_name_cz: "Kuchyňské spotřebiče",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Starostlivosť o telo a zdravie",
        category_name_cz: "Ošetření o těle a zdraví",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Chladenie a kúrenie",
        category_name_cz: "Chlazení a kouření",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Starostlivosť o bielizeň",
        category_name_cz: "Ošetření o bíléní",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Upratovacie spotrebiče",
        category_name_cz: "Upratovací spotřebiče",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Úprava vzduchu",
        category_name_cz: "Úprava vzduchu",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Drogéria",
    category_name_cz: "Drogerie",
    tag_name: "Drogéria",
    order: 1,
    icon: FaBottleDroplet,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/drogeria.jpg",
    subcategories: [
      {
        category_name_sk: "Pranie bielizne",
        category_name_cz: "Praní bílení",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Čistiace prostriedky",
        category_name_cz: "Čisticí prostředky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Upratovacie pomôcky",
        category_name_cz: "Upratovací pomůcky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Ekologická domácnosť",
        category_name_cz: "Ekologická domácnost",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Prípravky proti hmyzu a škodcom",
        category_name_cz: "Přípravek proti hmyzu a škodům",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Elektronika",
    category_name_cz: "Elektronika",
    tag_name: "Elektronika",
    order: 1,
    icon: MdPhoneAndroid,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/tv-elektro.jpg",
    subcategories: [
      {
        category_name_sk: "TV, audio, video",
        category_name_cz: "TV, audio, video",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Mobily, tablety, nositeľná elektronika",
        category_name_cz: "Mobilní telefony, tablety, nositelná elektronika",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Počítače a notebooky",
        category_name_cz: "Počítače a notebooky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Inteligentná domácnosť",
        category_name_cz: "Inteligentní domácnost",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Foto, kamery, optika",
        category_name_cz: "Fotoaparáty, kamery, optika",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Batérie a nabíjanie",
        category_name_cz: "Baterie a nabíjení",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Domáce spotrebiče",
        category_name_cz: "Domácí spotřebiče",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Filmy, knihy, hry",
    category_name_cz: "Filmy, knihy, hry",
    tag_name: "Filmy, knihy, hry",
    order: 1,
    icon: FaBookOpen,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/knihy.jpg",
    subcategories: [
      {
        category_name_sk: "Spoločenské hry a zábava",
        category_name_cz: "Společenské hry a zábava",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Čítanie",
        category_name_cz: "Čtení",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Mapy, sprievodcovia a nástenné mapy",
        category_name_cz: "Mapy, sprievodcové a nástenní mapy",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Počúvanie",
        category_name_cz: "Poslech",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Gaming",
    category_name_cz: "Gaming",
    tag_name: "Gaming",
    order: 1,
    icon: IoGameController,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/gaming.jpg",
    subcategories: [
      {
        category_name_sk: "Herné príslušenstvo",
        category_name_cz: "Herní příslušenství",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Hry na PC a konzoly",
        category_name_cz: "Hry na PC a konzoly",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Virtuálna realita",
        category_name_cz: "Virtuální realita",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Hobby",
    category_name_cz: "Hobby",
    tag_name: "Hobby",
    order: 1,
    icon: FaGuitar,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/hobby.jpg",
    subcategories: [
      {
        category_name_sk: "Rybárske potreby",
        category_name_cz: "Potřeby pro rybáře",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Fajčiarske potreby",
        category_name_cz: "Potřeby pro fajčáře",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Zberateľstvo",
        category_name_cz: "Sběratelské potřeby",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Investičné kovy",
        category_name_cz: "Investiční kovy",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Športové ocenenia a vlajky",
        category_name_cz: "Sportovní ocenění a vlajky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Galantéria a metráž",
        category_name_cz: "Galanterie a metráž",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Prírodné produkty",
        category_name_cz: "Přírodní produkty",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Hudobniny",
        category_name_cz: "Hudební nástroje",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Pre domácich majstrov a záhradkárov",
        category_name_cz: "Pro domácí mistrů a zahradkářů",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Zábava",
        category_name_cz: "Zábava",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Hračky",
    category_name_cz: "Hračky",
    tag_name: "Hračky",
    order: 1,
    icon: MdToys,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/hracky.jpg",
    subcategories: [
      {
        category_name_sk: "Stavebnice a kocky",
        category_name_cz: "Stavebnice a kocky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Hračky na záhradu",
        category_name_cz: "Hračky na zahradu",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Hry na profesie a napodobňovanie",
        category_name_cz: "Hry na profesní a napodobňování",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Bábiky a doplnky pre bábiky",
        category_name_cz: "Bábiky a doplňky pro bábiky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Plyšové hračky",
        category_name_cz: "Plyšové hračky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Detské zbrane a príslušenstvo",
        category_name_cz: "Dětské zbraně a příslušenství",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Detské autá a doprava",
        category_name_cz: "Dětské auta a doprava",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Interaktívne a robotické hračky",
        category_name_cz: "Interaktivní a robotické hračky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Motorické a didaktické hračky",
        category_name_cz: "Motorické a didaktické hračky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Bábky a bábkové divadlá",
        category_name_cz: "Bábky a bábkové divadlá",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Hrkálky a hryzadlá",
        category_name_cz: "Hrkálky a hryzadlá",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Kreatívne hračky a tvorenie pre deti",
        category_name_cz: "Kreativní hračky a tvorba pro děti",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Chovateľské potreby",
    category_name_cz: "Potřeby pro chovatele",
    tag_name: "Chovateľské potreby",
    order: 1,
    icon: BiSolidDog,
    subcategories: [
      {
        category_name_sk: "Akvaristika",
        category_name_cz: "Akvaristika",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Teráristika",
        category_name_cz: "Teréristika",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Pre mačky",
        category_name_cz: "Pro mačky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Pre psov",
        category_name_cz: "Pro psy",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Pre vtáky",
        category_name_cz: "Pro ptáky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Pre kone",
        category_name_cz: "Pro koně",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Pre hlodavce",
        category_name_cz: "Pro hlodavce",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Pre hospodárske zvieratá",
        category_name_cz: "Pro hospodářské zvířata",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Veterinárna lekáreň",
        category_name_cz: "Veterinární lékárna",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Kancelária a papiernictvo",
    category_name_cz: "Kancelář a papiernictvo",
    tag_name: "Kancelária a papiernictvo",
    order: 1,
    icon: FaPencilAlt,
    subcategories: [
      {
        category_name_sk: "Kancelárske potreby",
        category_name_cz: "Kancelářské potřeby",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Kancelárska technika a vybavenie prevádzok",
        category_name_cz: "Kancelářská technika a vybavení preváděk",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Školské potreby",
        category_name_cz: "Školské potřeby",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Papiernictvo",
        category_name_cz: "Papiernictvo",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Kozmetika a parfumy",
    category_name_cz: "Kozmetika a parfumy",
    tag_name: "Kozmetika a parfumy",
    order: 1,
    icon: GiDelicatePerfume,
    subcategories: [
      {
        category_name_sk: "Kozmetika",
        category_name_cz: "Kozmetika",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Vlasy",
        category_name_cz: "Vlasy",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Pleť",
        category_name_cz: "Pleť",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Telo",
        category_name_cz: "Tělo",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Dekoratívna kozmetika",
        category_name_cz: "Dekorativa kozmetika",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Nechty",
        category_name_cz: "Nechty",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Zuby",
        category_name_cz: "Zuby",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Opaľovanie",
        category_name_cz: "Opačování",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Uši",
        category_name_cz: "Uši",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Hygiena a hygienické potreby",
        category_name_cz: "Hyždění a hygienické potřeby",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Kozmetické pomôcky",
        category_name_cz: "Kozmetické pomůcky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Deti",
        category_name_cz: "Děti",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Oblečenie, obuv a doplnky",
    category_name_cz: "Oblečení, obuv a doplňky",
    tag_name: "Oblečenie, obuv a doplnky",
    order: 1,
    icon: FaTshirt,
    subcategories: [
      {
        category_name_sk: "Pánske oblečenie",
        category_name_cz: "Pánské oblečení",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Dámske oblečenie",
        category_name_cz: "Dámské oblečení",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Detské oblečenie",
        category_name_cz: "Dětské oblečení",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Módne doplnky",
        category_name_cz: "Módní doplňky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Obuv",
        category_name_cz: "Obuv",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Šport a outdoor",
    category_name_cz: "Šport a outdoor",
    tag_name: "Šport a outdoor",
    order: 1,
    icon: MdSportsFootball,
    subcategories: [
      {
        category_name_sk: "Outdoorové vybavenie",
        category_name_cz: "Outdoorové vybavení",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Športy",
        category_name_cz: "Športy",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Športtestery a inteligentné prístroje",
        category_name_cz: "Športtestery a inteligentní přístroje",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Veľké spotrebiče",
    category_name_cz: "Velké spotřebiče",
    tag_name: "Veľké spotrebiče",
    order: 1,
    icon: GiWashingMachine,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/kuchyna-elektro.jpg",
    subcategories: [
      {
        category_name_sk: "Práčky a sušičky",
        category_name_cz: "Práčky a sušičky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Spotrebiče do kuchyne",
        category_name_cz: "Spotřebiče do kuchyňe",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Zdravie",
    category_name_cz: "Zdraví",
    tag_name: "Zdravie",
    order: 1,
    icon: GiHealthNormal,
    subcategories: [
      {
        category_name_sk: "Zdravotnícke potreby",
        category_name_cz: "Zdravotní potřeby",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Zdravotné prístroje",
        category_name_cz: "Zdravotní přístroje",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Očná optika",
        category_name_cz: "Oční optika",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Lieky a doplnky stravy",
        category_name_cz: "Léky a doplňky stravy",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Koronavírus",
        category_name_cz: "Koronavírus",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Ochrana dýchacích ciest",
        category_name_cz: "Ochrana dýchacích ciest",
        order: 2,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Erotické pomôcky",
    category_name_cz: "Erotické pomůcky",
    tag_name: "Erotické pomôcky",
    order: 1,
    icon: GiLips,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/eroticke-pomocky.jpg",
    subcategories: [
      {
        category_name_sk: "Erotická kozmetika a kondómy",
        category_name_cz: "Erotická kozmetika a kondómy",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Erotická literatúra a filmy",
        category_name_cz: "Erotická literára a filmy",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Erotické hry a hračky",
        category_name_cz: "Erotické hry a hračky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Vibrátory a dildá",
        category_name_cz: "Vibrátory a dildá",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Erotické oblečenie, bielizeň a doplnky",
        category_name_cz: "Erotické oblečení, bílení a doplňky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "BDSM a fetiš",
        category_name_cz: "BDSM a fetiš",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Análne hračky",
        category_name_cz: "Anální hračky",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Erotické pomôcky pre ženy",
        category_name_cz: "Erotické pomůcky pro ženy",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Erotické pomôcky pre mužov",
        category_name_cz: "Erotické pomůcky pro muže",
        order: 2,
        subcategories: [],
      },
      {
        category_name_sk: "Erotický nábytok a doplnky",
        category_name_cz: "Erotický nábytek a doplňky",
        order: 2,
        subcategories: [],
      },
    ],
  },
];

export const foodCategories = [
  {
    category_name_sk: "Nealkoholické nápoje",
    category_name_cz: "Nealkoholické nápoje",
    tag_name: "Nealkoholické nápoje",
    order: 2,
    icon: MdFoodBank,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/nealko.jpg",
    subcategories: [],
  },
  {
    category_name_sk: "Alkoholické nápoje",
    category_name_cz: "Alkoholické nápoje",
    tag_name: "Alkoholické nápoje",
    order: 2,
    icon: MdFoodBank,

    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/alko.jpg",
    subcategories: [],
  },
  {
    category_name_sk: "Čerstvé potraviny",
    category_name_cz: "Čerstvé potraviny",
    tag_name: "Čerstvé potraviny",
    order: 2,
    icon: MdFoodBank,

    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/cerstve_potraviny.jpg",
    subcategories: [
      {
        category_name_sk: "Maslá, margaríny a nátierky",
        category_name_cz: "Maslá, margaríny a nátierky",
        order: 3,
        subcategories: [],
      },
      {
        category_name_sk: "Údeniny a lahôdky",
        category_name_cz: "Údeniny a lahôdky",
        order: 3,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Trvanlivé potraviny",
    category_name_cz: "Trvanlivé potraviny",
    tag_name: "Trvanlivé potraviny",
    order: 2,
    icon: MdFoodBank,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/trvanlive_potraviny.jpg",
    subcategories: [
      {
        category_name_sk: "Slané snacky",
        category_name_cz: "Slané snacky",
        order: 3,
        subcategories: [],
      },
      {
        category_name_sk: "Džemy, medy, čokokrémy",
        category_name_cz: "Džemy, medy, čokokrémy",
        order: 3,
        subcategories: [],
      },
      {
        category_name_sk: "Cestoviny, ryža, strukoviny",
        category_name_cz: "Cestoviny, ryža, strukoviny",
        order: 3,
        subcategories: [],
      },
      {
        category_name_sk: "Hotové a instantné jedlá",
        category_name_cz: "Hotové a instantné jedlá",
        order: 3,
        subcategories: [],
      },
      {
        category_name_sk: "Konzervované potraviny",
        category_name_cz: "Konzervované potraviny",
        order: 3,
        subcategories: [],
      },
      {
        category_name_sk: "Prísady na varenie a pečenie",
        category_name_cz: "Prísady na varenie a pečenie",
        order: 3,
        subcategories: [],
      },
      {
        category_name_sk: "Omáčky, kečupy, horčice",
        category_name_cz: "Omáčky, kečupy, horčice",
        order: 3,
        subcategories: [],
      },
      {
        category_name_sk: "Dia, racio, bio potraviny",
        category_name_cz: "Dia, racio, bio potraviny",
        order: 3,
        subcategories: [],
      },
      {
        category_name_sk: "Cukrárské suroviny",
        category_name_cz: "Cukrárské suroviny",
        order: 3,
        subcategories: [],
      },
      {
        category_name_sk: "Korenie a ochucovadlá",
        category_name_cz: "Korenie a ochucovadlá",
        order: 3,
        subcategories: [],
      },
      {
        category_name_sk: "Majonézy, tatárske omáčky a dresingy",
        category_name_cz: "Majonézy, tatárské omáčky a dresingy",
        order: 3,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Mrazené potraviny",
    category_name_cz: "Mrazené potraviny",
    tag_name: "Mrazené potraviny",
    order: 2,
    icon: MdFoodBank,

    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/zamrznute_jedlo.jpg",
    subcategories: [],
  },
  {
    category_name_sk: "Sladkosti",
    category_name_cz: "Sladkosti",
    tag_name: "Sladkosti",
    order: 2,
    icon: MdFoodBank,

    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/sladkosti.jpg",
    subcategories: [],
  },
  {
    category_name_sk: "Čaje a bylinky",
    category_name_cz: "Čaje a bylinky",
    tag_name: "Čaje a bylinky",
    order: 2,
    icon: MdFoodBank,

    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/caje.jpg",
    subcategories: [],
  },
  {
    category_name_sk: "Káva a kávové kapsuly",
    category_name_cz: "Káva a kávové kapsuly",
    tag_name: "Káva a kávové kapsuly",
    order: 2,
    icon: MdFoodBank,
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/kava.jpg",
    subcategories: [
      {
        category_name_sk: "Káva",
        category_name_cz: "Káva",
        order: 3,
        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Rastlinné alternatívy",
    category_name_cz: "Rastlinné alternatívy",
    tag_name: "Rastlinné alternatívy",
    order: 2,
    icon: MdFoodBank,

    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/rastlinne_aleternativ.jpg",
    subcategories: [],
  },
  {
    category_name_sk: "Instantné a energetické nápoje",
    category_name_cz: "Instantné a energetické nápoje",
    tag_name: "Instantné a energetické nápoje",
    order: 2,
    icon: MdFoodBank,

    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/energy.jpg",
    subcategories: [],
  },
  {
    category_name_sk: "Mliečne a chladené potraviny",
    category_name_cz: "Mléčné a chladené potraviny",
    tag_name: "Mliečne a chladené potraviny",
    order: 2,
    icon: MdFoodBank,

    subcategories: [],
    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/mliec_ne_.jpg",
  },
  {
    category_name_sk: "Pekáreň a cukráreň",
    category_name_cz: "Pečárna a cukrárna",
    tag_name: "Pekáreň a cukráreň",
    order: 2,
    icon: MdFoodBank,

    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/pec_ivo.jpg",
    subcategories: [
      {
        category_name_sk: "Pečivo",
        category_name_cz: "Pečivo",
        order: 3,
        icon: MdFoodBank,

        subcategories: [],
      },
    ],
  },
  {
    category_name_sk: "Oriešky, sušené plody a semená",
    category_name_cz: "Ořechy, sušené ořechy a semena",
    icon: MdFoodBank,

    image:
      "https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/categories/food/ories_ky.jpg",
    order: 2,
    subcategories: [],
  },
];

export const servicesCategories = [
  {
    category_name_sk: "Služby",
    category_name_cz: "Služby",
    tag_name: "Služby",
    order: 2,
    icon: MdOutlineDesignServices,
    subcategories: [],
  },
  {
    category_name_sk: "Kurzy",
    category_name_cz: "Kurzy",
    tag_name: "Kurzy",
    order: 2,
    icon: LiaChalkboardTeacherSolid,
    subcategories: [],
  },
  {
    category_name_sk: "Telefonovanie, internet, televízia",
    category_name_cz: "Telefonování, internet, televize",
    tag_name: "Telefonovanie, internet, televízia",
    order: 2,
    icon: FaTv,
    subcategories: [],
  },
  {
    category_name_sk: "Počítačové služby",
    category_name_cz: "Počítačové služby",
    tag_name: "Počítačové služby",
    order: 2,
    icon: FaComputer,
    subcategories: [],
  },
  {
    category_name_sk: "Fotografické služby",
    category_name_cz: "Fotografické služby",
    tag_name: "Fotografické služby",
    order: 2,
    icon: MdPhotoCamera,
    subcategories: [],
  },
  {
    category_name_sk: "Gravírovacie služby",
    category_name_cz: "Gravírovací služby",
    tag_name: "Gravírovacie služby",
    order: 2,
    icon: IoAnalyticsOutline,
    subcategories: [],
  },
  {
    category_name_sk: "Tlačiarenské služby",
    category_name_cz: "Tlačiarenské služby",
    tag_name: "Tlačiarenské služby",
    order: 2,
    icon: FiPrinter,
    subcategories: [],
  },
];

export const travelCategories = [
  {
    category_name_sk: "Ubytovanie a cestovanie",
    category_name_cz: "Ubytování a cestování",
    tag_name: "Ubytovanie a cestovanie",
    order: 2,
    icon: MdOutlineTravelExplore,
    subcategories: [],
  },
];

export const eventCategories = [
  {
    category_name_sk: "Podujatia",
    category_name_cz: "Podujatia",
    tag_name: "Podujatia",
    order: 2,
    icon: BsCalendar4Event,
    subcategories: [],
  },
];

export const bazarCategories = [
  {
    category_name_sk: "Použitý tovar",
    category_name_cz: "Použitý tovar",
    tag_name: "Použitý tovar",
    order: 2,
    icon: IoMdRefreshCircle,
    subcategories: [],
  },
];
