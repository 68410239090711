import React, { useState, useEffect, useRef } from "react";
import api from "../services/api";
import { Button, Input, Link, Spinner } from "@nextui-org/react";
import { ProductCard } from "./ProductCard.jsx";
import { SearchIcon } from "./SearchIcon.jsx";
import { ShopCard } from "./ShopCard";
import { TextCard } from "./TextCard";
import { TravelCard } from "./TravelCard";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

// Update the SuggestionCard component
const SuggestionCard = ({ suggestion, query, onClick }) => {
  const index = suggestion.name.toLowerCase().indexOf(query.toLowerCase());
  if (index === -1) {
    return (
      <div
        className="px-3 py-1 text-sm cursor-pointer hover:bg-gray-100"
        onClick={(e) => {
          e.stopPropagation(); // Prevent event from bubbling up
          onClick(suggestion.name);
        }}
      >
        {suggestion.name}
      </div>
    );
  }

  const before = suggestion.name.slice(0, index);
  const match = suggestion.name.slice(index, index + query.length);
  const after = suggestion.name.slice(index + query.length);

  return (
    <div
      className="px-3 py-1 text-sm cursor-pointer hover:bg-gray-100"
      onClick={(e) => {
        e.stopPropagation(); // Prevent event from bubbling up
        onClick(suggestion.name);
      }}
    >
      {before}
      <span className="font-semibold">{match}</span>
      {after}
    </div>
  );
};

const inputStyle = {
  fontSize: "16px", // Ensures the font size is at least 16px to prevent zoom on mobile
  width: "100%",
  padding: "0.5rem",
  transition: "background-color 0.3s ease",
};

const LOCALES_WITH_FULL_SEARCH = ["sk", "cs"];

const SearchBar = ({ isOpen, closeSearch, initialQuery, onQueryChange }) => {
  const [query, setQuery] = useState(initialQuery);
  const [results, setResults] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [shops, setShops] = useState([]);
  const [categories, setCategories] = useState([]);
  const [travels, setTravels] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [suggestion, setSuggestion] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const previousSuggestionsRef = useRef([]);

  // **New State for Last User Queries**
  const [lastUserQueries, setLastUserQueries] = useState([]);

  const [currentLocale, setCurrentLocale] = useState(() => {
    return localStorage.getItem("locale") || "sk";
  });

  const debounceDelay = 500;
  const { t } = useTranslation();

  const inputRef = useRef(null);
  const desktopInputRef = useRef(null);
  const abortControllerRef = useRef(null);
  const latestRequestIdRef = useRef(0);
  const navigate = useNavigate();

  const [showSuggestions, setShowSuggestions] = useState(true);

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  useEffect(() => {
    if (isOpen && LOCALES_WITH_FULL_SEARCH.includes(currentLocale)) {
      // Use a slightly longer timeout for desktop
      setTimeout(() => {
        if (window.innerWidth >= 1024) {
          // lg breakpoint
          desktopInputRef.current?.focus();
        } else {
          inputRef.current?.focus();
        }
      }, 100);

      // **Fetch Last User Queries When Search Opens**
      const fetchLastUserQueries = async () => {
        try {
          const response = await api.get("get-last-user-queries/");
          if (response.data && response.data.data) {
            setLastUserQueries(response.data.data);
          } else {
            setLastUserQueries([]);
          }
        } catch (error) {
          console.error("Error fetching last user queries: ", error);
          setLastUserQueries([]);
        }
      };
      fetchLastUserQueries();
    }
  }, [isOpen, currentLocale]);

  useEffect(() => {
    if (!isOpen) {
      setSuggestions([]);
      setLastUserQueries([]);
      return;
    }

    if (query.length === 0) {
      // Fetch most-searched when query is empty
      const fetchMostSearched = async () => {
        try {
          const response = await api.get("get-most-searched-queries/");
          if (response.data && response.data.data) {
            setSuggestions(
              response.data.data.map((item) => ({
                ...item,
                isMostSearched: true,
              }))
            );
          } else {
            setSuggestions([]);
          }
        } catch (error) {
          console.error("Error fetching most searched: ", error);
          setSuggestions([]);
        }
      };
      fetchMostSearched();
    } else {
      // Existing auto-suggest logic with debounce
      const fetchSuggestions = async () => {
        try {
          const response = await api.get(`auto-suggest/?q=${query}`);
          if (response.data && response.data.suggestions) {
            const filteredSuggestions = response.data.suggestions
              .filter(
                (suggestion) =>
                  suggestion.name.toLowerCase().includes(query.toLowerCase()) &&
                  suggestion.name.toLowerCase() !== query.toLowerCase()
              )
              .map((item) => ({ ...item, isMostSearched: false }));

            // Check if we're transitioning from empty to non-empty suggestions
            if (
              previousSuggestionsRef.current.length === 0 &&
              filteredSuggestions.length > 0
            ) {
              setSuggestions([]); // Clear suggestions first
              setTimeout(
                () => setSuggestions(filteredSuggestions.slice(0, 10)),
                0
              );
            } else {
              setSuggestions(filteredSuggestions.slice(0, 10));
            }

            // Update the ref with the current suggestions
            previousSuggestionsRef.current = filteredSuggestions;
          } else {
            setSuggestions([]);
            previousSuggestionsRef.current = [];
          }
        } catch (error) {
          console.error("Error fetching suggestions: ", error);
          setSuggestions([]);
          previousSuggestionsRef.current = [];
        }
      };

      const timer = setTimeout(fetchSuggestions, 300); // Debounce

      return () => clearTimeout(timer);
    }
  }, [query, isOpen]);

  const handleQueryChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    onQueryChange(newQuery);
    setShowSuggestions(true); // Re-enable suggestions when user types
    if (newQuery.length === 0) {
      setSuggestion("");
    }
  };

  const handleClear = () => {
    setQuery("");
    onQueryChange("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchButtonClick();
    } else if (e.key === "Tab" && suggestion) {
      e.preventDefault();
      setQuery(suggestion);
      onQueryChange(suggestion);
      setSuggestion("");
    }
  };

  const handleSuggestionClick = (suggestionText) => {
    setQuery(suggestionText);
    onQueryChange(suggestionText);
    setSuggestions([]); // Clear suggestions
    setLastUserQueries([]); // Clear last user queries
    setShowSuggestions(false); // Hide suggestions
    // Trigger a search with the new query
    const fetchResults = async () => {
      try {
        setIsFetching(true);
        const response = await api.get(`search?q=${suggestionText}`);
        setResults(response.data.products);
        setShops(response.data.shops);
        setCategories(response.data.categories);
        setTravels(response.data.travels);
        setManufacturers(response.data.manufacturers);
        setIsFetching(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsFetching(false);
      }
    };
    fetchResults();
  };

  const handleProductClick = async (productSlug) => {
    console.log("Product clicked:", productSlug);
    try {
      const response = await api.post("query-rating/", {
        product_slug: productSlug,
        search_query: query,
      });
      console.log("Query rating response:", response);

      // **Store the new query in lastUserQueries**
      // const updatedQueries = [
      //   query,
      //   ...lastUserQueries.filter((q) => q !== query),
      // ];
      // setLastUserQueries(updatedQueries.slice(0, 10)); // Keep only the latest 10 queries
      // localStorage.setItem(
      //   "lastUserQueries",
      //   JSON.stringify(updatedQueries.slice(0, 10))
      // );
    } catch (error) {
      console.error("Error updating query rating:", error);
    } finally {
      console.log("Attempting to navigate to:", `/view-product/${productSlug}`);
      closeSearch(); // Close the search modal
      navigate(`/view-product/${productSlug}`);
      closeSearch();
    }
  };

  // Add this new function to filter last user queries
  const filterLastUserQueries = (queries, currentQuery) => {
    if (!currentQuery) return queries;
    return queries.filter((query) =>
      query.name.toLowerCase().includes(currentQuery.toLowerCase())
    );
  };

  const handleSearchButtonClick = async () => {
    if (currentLocale !== "sk") {
      if (shops.length > 0) {
        navigate(`/view-shop/${shops[0].id}`);
        closeSearch();
      }
    } else {
      // For Slovak locale, first check autosuggest results
      try {
        const response = await api.get(`auto-suggest/?q=${query}`);
        if (response.data?.suggestions?.length > 0) {
          const firstSuggestion = response.data.suggestions[0];
          if (firstSuggestion.type === "shop") {
            navigate(`/view-shop/${firstSuggestion.id}`);
            closeSearch();
            return;
          }
        }
        // If no shop suggestion found, proceed with default behavior
        navigate(
          `/view-more-products/${encodeURIComponent(response.data.suggestions[0].name)}`
        );
        closeSearch();
      } catch (error) {
        console.error("Error checking autosuggest:", error);
        // Fallback to default behavior
        navigate(`/view-more-products/${query}`);
        closeSearch();
      }
    }
  };

  const searchInput = (
    <div className="relative w-full lg:px-4">
      <Input
        ref={inputRef}
        type="text"
        value={query}
        onChange={handleQueryChange}
        onKeyDown={handleKeyDown}
        startContent={<SearchIcon size={18} />}
        endContent={
          <div className="flex items-center gap-2">
            {query && (
              <button onClick={handleClear} className="focus:outline-none">
                <XMarkIcon className="h-4 w-4 text-gray-400 hover:text-gray-600" />
              </button>
            )}
            <button
              onClick={handleSearchButtonClick}
              className="bg-primary text-white px-8 -mr-4 py-4 rounded-r-lg "
            >
              {t("Hľadať")}
            </button>
          </div>
        }
        className="w-full"
        placeholder={t(
          "Prehľadávajte produkty, kategórie, obchody, zážitky..."
        )}
        variant="bordered"
        style={inputStyle}
      />
    </div>
  );

  // Fetch data and manage state
  useEffect(() => {
    if (!isOpen) return;

    const handler = setTimeout(() => {
      if (query.length > 0) {
        const fetchResults = async () => {
          try {
            // Cancel any ongoing requests
            if (abortControllerRef.current) {
              abortControllerRef.current.abort();
            }

            // Create a new AbortController and increment requestId
            abortControllerRef.current = new AbortController();
            const currentRequestId = latestRequestIdRef.current + 1;
            latestRequestIdRef.current = currentRequestId;

            setIsFetching(true);
            const response = await api.get(`search?q=${query}`, {
              signal: abortControllerRef.current.signal,
            });

            // Only update state if this is still the most recent request
            if (currentRequestId === latestRequestIdRef.current) {
              setResults(response.data.products);
              setShops(response.data.shops);
              setCategories(response.data.categories);
              setTravels(response.data.travels);
              setManufacturers(response.data.manufacturers);
              setIsFetching(false);

              // Save to localStorage
              localStorage.setItem("lastSearchQuery", query);
              localStorage.setItem(
                "lastSearchResults",
                JSON.stringify(response.data)
              );
            }
          } catch (error) {
            if (error.name === "AbortError") {
              console.log("Fetch aborted");
            } else {
              console.error("Error fetching data: ", error);
            }
            setIsFetching(false);
          }
        };
        fetchResults();
      } else {
        setResults([]);
        setShops([]);
        setCategories([]);
        setTravels([]);
        setManufacturers([]);
        localStorage.removeItem("lastSearchQuery");
        localStorage.removeItem("lastSearchResults");
      }
    }, debounceDelay);

    // Cleanup function
    return () => {
      clearTimeout(handler);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [query, debounceDelay, isOpen]);

  // Lock body scroll when search is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"; // Cleanup on unmount
    };
  }, [isOpen]);

  useEffect(() => {
    const handleLocaleChange = () => {
      setCurrentLocale(localStorage.getItem("locale") || "sk");
    };

    window.addEventListener("storage", handleLocaleChange);
    return () => window.removeEventListener("storage", handleLocaleChange);
  }, []);

  if (!isOpen) return null;

  return (
    <>
      {/* Desktop Version */}
      <div
        className="hidden lg:block fixed inset-0 bg-black bg-opacity-30 shadow-lg backdrop-blur-sm z-[3000]"
        onClick={closeSearch}
      >
        <div
          className="bg-white rounded-lg py-3 mt-3 max-w-[1000px] mx-auto z-[4000] max-h-[90vh] scrollbar-hide overflow-y-auto"
          onClick={(e) => e.stopPropagation()} // Prevent event bubbling to the backdrop
        >
          <div className="flex justify-center">{searchInput}</div>
          {LOCALES_WITH_FULL_SEARCH.includes(currentLocale) && (
            <div className="flex items-center gap-1 justify-center text-lightgray mt-2 text-sm ">
              <InformationCircleIcon className="h-5 w-5" />{" "}
              {t(
                "9.11.2024 spouštíme vyhledávání kategorií a produktů pro český trh"
              )}
            </div>
          )}

          {/* Display Last User Queries Above Suggestions and Most Searched */}
          {filterLastUserQueries(lastUserQueries, query).length > 0 &&
            LOCALES_WITH_FULL_SEARCH.includes(currentLocale) && (
              <div className="px-10 py-4">
                <div className="font-bold text-xl pb-4">
                  Vaše posledné vyhľadávania
                </div>
                <div className="flex flex-col gap-2">
                  {filterLastUserQueries(lastUserQueries, query).map(
                    (suggestion) => (
                      <SuggestionCard
                        key={suggestion.id}
                        suggestion={suggestion}
                        query={query}
                        onClick={handleSuggestionClick}
                      />
                    )
                  )}
                </div>
              </div>
            )}

          {/* Display Suggestions or Most Searched */}
          {suggestions.length > 0 &&
            showSuggestions &&
            LOCALES_WITH_FULL_SEARCH.includes(currentLocale) && (
              <div className="px-10 py-4">
                {suggestions.some((s) => s.isMostSearched) && (
                  <>
                    <div className="font-bold text-xl pb-4">
                      Často vyhľadávané
                    </div>
                    <div className="flex flex-col gap-2 mb-4">
                      {suggestions
                        .filter((s) => s.isMostSearched)
                        .map((suggestion) => (
                          <SuggestionCard
                            key={suggestion.id}
                            suggestion={suggestion}
                            query={query}
                            onClick={handleSuggestionClick}
                          />
                        ))}
                    </div>
                  </>
                )}

                {suggestions.some((s) => !s.isMostSearched) && (
                  <>
                    <div className="font-bold text-xl pb-4">
                      {t("Odporúčania")}
                    </div>
                    <div className="flex flex-col gap-2">
                      {suggestions
                        .filter((s) => !s.isMostSearched)
                        .map((suggestion) => (
                          <SuggestionCard
                            key={suggestion.id}
                            suggestion={suggestion}
                            query={query}
                            onClick={handleSuggestionClick}
                          />
                        ))}
                    </div>
                  </>
                )}
              </div>
            )}

          {isFetching ? (
            <div className="items-center flex justify-center py-4">
              <Spinner />
            </div>
          ) : results.length === 0 &&
            manufacturers.length === 0 &&
            shops.length === 0 &&
            categories.length === 0 &&
            travels.length === 0 ? (
            <div className="text-lightgray font-semibold text-center py-12">
              Neboli nájdené žiadne výsledky
            </div>
          ) : (
            <div className="overflow-y-auto px-10">
              {LOCALES_WITH_FULL_SEARCH.includes(currentLocale) ? (
                <>
                  {/* Render Categories */}
                  {categories.length > 0 && (
                    <>
                      <div className="py-8">
                        <hr />
                      </div>
                      <div className="font-bold text-2xl pb-6">Kategórie</div>
                      <div className="flex flex-wrap gap-2">
                        {categories.map((category) => (
                          <div key={category.id} className="pt-4">
                            <TextCard
                              itemData={category.name}
                              href="/category/"
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  {/* Render Travels */}
                  {travels.length > 0 && (
                    <>
                      <div className="py-8">
                        <hr />
                      </div>
                      <div className="font-bold text-2xl pb-6">Cestovanie</div>
                      <div className="flex flex-wrap gap-4">
                        {travels?.slice(0, 4).map((travel) => (
                          <div key={travel.id}>
                            <TravelCard itemData={travel} />
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  {/* Render Products */}
                  {(results.single_offer?.length > 0 ||
                    results.multiple_offers?.length > 0) && (
                    <>
                      <div className="py-8">
                        <hr />
                      </div>
                      <div className="font-bold text-2xl">{t("Produkty")}</div>

                      {/* Multiple Offers Products */}
                      {results.multiple_offers?.length > 0 && (
                        <>
                          <div className="font-semibold text-lg pt-4">
                            {t("Viac ponúk")}
                          </div>
                          <div className="pt-5 flex flex-wrap">
                            {results.multiple_offers
                              ?.slice(0, 12)
                              .map((itemData) => (
                                <div
                                  key={itemData.id}
                                  className="pt-4 mx-auto"
                                  onClick={() =>
                                    handleProductClick(itemData.slug)
                                  }
                                >
                                  <ProductCard itemData={itemData} t={t} />
                                </div>
                              ))}
                          </div>
                        </>
                      )}

                      {/* Single Offer Products */}
                      {results.single_offer?.length > 0 && (
                        <>
                          <div className="font-semibold text-lg pt-4">
                            {t("Jedna ponuka")}
                          </div>
                          <div className="pt-5 flex flex-wrap">
                            {results.single_offer
                              ?.slice(0, 12)
                              .map((itemData) => (
                                <div
                                  key={itemData.id}
                                  className="pt-4 mx-auto"
                                  onClick={() =>
                                    handleProductClick(itemData.slug)
                                  }
                                >
                                  <ProductCard itemData={itemData} t={t} />
                                </div>
                              ))}
                          </div>
                        </>
                      )}

                      <div className="text-right pt-5">
                        <Button
                          as={Link}
                          href={`/view-more-products/${encodeURIComponent(query)}`}
                          color="primary"
                          className="font-semibold px-8 rounded-3xl"
                        >
                          {t("Zobraziť viac produktov")}
                        </Button>
                      </div>
                    </>
                  )}

                  {/* Render Manufacturers */}
                  {manufacturers.length > 0 && (
                    <>
                      <div className="py-8">
                        <hr />
                      </div>
                      <div className="font-bold text-2xl pb-6">Výrobca</div>
                      <div className="pb-8">
                        <div className="flex flex-wrap gap-4">
                          {manufacturers.map((manufacturer) => (
                            <div key={manufacturer?.id}>
                              <TextCard
                                itemData={manufacturer.toUpperCase()}
                                href={"/view-manufacturer/"}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : null}

              {/* Always render Shops section regardless of locale */}
              {shops.length > 0 && (
                <>
                  <div className="py-8">
                    <hr />
                  </div>
                  <div className="font-bold text-2xl pb-6">Obchody</div>
                  <div className="pb-8">
                    <div className="flex flex-wrap gap-4">
                      {shops.map((shop) => (
                        <div key={shop.id}>
                          <ShopCard itemData={shop} />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Mobile Version */}
      <div
        className="block lg:hidden fixed inset-0 bg-white z-[3000] overflow-y-auto"
        onClick={(e) => e.stopPropagation()} // Prevent closing on container click
      >
        {/* Close button for mobile */}
        <button
          className="absolute top-4 right-4 text-gray-600"
          onClick={closeSearch}
        >
          &#10005; {/* Unicode for "X" */}
        </button>

        {/* Mobile search input */}
        <div className="pt-10 px-6">{searchInput}</div>

        {/* Display Last User Queries for Mobile Above Suggestions */}
        {filterLastUserQueries(lastUserQueries, query).length > 0 &&
          LOCALES_WITH_FULL_SEARCH.includes(currentLocale) && (
            <div className="px-6 py-4">
              <div className="font-bold text-xl pb-4">
                Vaše posledné vyhľadávania
              </div>
              <div className="flex flex-col gap-2">
                {filterLastUserQueries(lastUserQueries, query).map(
                  (suggestion) => (
                    <SuggestionCard
                      key={suggestion.id}
                      suggestion={suggestion}
                      query={query}
                      onClick={handleSuggestionClick}
                    />
                  )
                )}
              </div>
            </div>
          )}

        {/* Display Suggestions for Mobile */}
        {suggestions.length > 0 &&
          showSuggestions &&
          LOCALES_WITH_FULL_SEARCH.includes(currentLocale) && (
            <div className="px-6 py-4">
              {suggestions.some((s) => s.isMostSearched) && (
                <>
                  <div className="font-bold text-xl pb-4">
                    Často vyhľadávané
                  </div>
                  <div className="flex flex-col gap-2 mb-4">
                    {suggestions
                      .filter((s) => s.isMostSearched)
                      .map((suggestion) => (
                        <SuggestionCard
                          key={suggestion.id}
                          suggestion={suggestion}
                          query={query}
                          onClick={handleSuggestionClick}
                        />
                      ))}
                  </div>
                </>
              )}

              {suggestions.some((s) => !s.isMostSearched) && (
                <>
                  <div className="font-bold text-xl pb-4">
                    {t("Odporúčania")}
                  </div>
                  <div className="flex flex-col gap-2">
                    {suggestions
                      .filter((s) => !s.isMostSearched)
                      .map((suggestion) => (
                        <SuggestionCard
                          key={suggestion.id}
                          suggestion={suggestion}
                          query={query}
                          onClick={handleSuggestionClick}
                        />
                      ))}
                  </div>
                </>
              )}
            </div>
          )}

        {/* Results area for mobile */}
        <div className="px-6 py-4">
          {isFetching ? (
            <div className="items-center flex justify-center py-4">
              <Spinner />
            </div>
          ) : results.length === 0 &&
            manufacturers.length === 0 &&
            shops.length === 0 &&
            categories.length === 0 &&
            travels.length === 0 ? (
            <div className="text-lightgray font-semibold text-center py-12">
              Neboli nájdené žiadne výsledky
            </div>
          ) : (
            <div>
              {LOCALES_WITH_FULL_SEARCH.includes(currentLocale) ? (
                <>
                  {/* Categories */}
                  {categories.length > 0 && (
                    <>
                      <div className="py-8">
                        <hr />
                      </div>
                      <div className="font-bold text-2xl pb-6">Kategórie</div>
                      <div className="flex flex-wrap gap-2">
                        {categories.map((category) => (
                          <div key={category.id} className="pt-4">
                            <TextCard
                              itemData={category.name}
                              href="/category/"
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  {/* Products */}
                  {(results.single_offer?.length > 0 ||
                    results.multiple_offers?.length > 0) && (
                    <>
                      <div className="py-8">
                        <hr />
                      </div>
                      <div className="font-bold text-2xl">{t("Produkty")}</div>

                      {/* Multiple Offers Products */}
                      {results.multiple_offers?.length > 0 && (
                        <>
                          <div className="font-semibold text-lg pt-4">
                            {t("Viac ponúk")}
                          </div>
                          <div className="pt-5 flex flex-wrap">
                            {results.multiple_offers
                              ?.slice(0, 12)
                              .map((itemData) => (
                                <div
                                  key={itemData.id}
                                  className="pt-4 mx-auto"
                                  onClick={() =>
                                    handleProductClick(itemData.slug)
                                  }
                                >
                                  <ProductCard itemData={itemData} t={t} />
                                </div>
                              ))}
                          </div>
                        </>
                      )}

                      {/* Single Offer Products */}
                      {results.single_offer?.length > 0 && (
                        <>
                          <div className="font-semibold text-lg pt-4">
                            {t("Jedna ponuka")}
                          </div>
                          <div className="pt-5 flex flex-wrap">
                            {results.single_offer
                              ?.slice(0, 12)
                              .map((itemData) => (
                                <div
                                  key={itemData.id}
                                  className="pt-4 mx-auto"
                                  onClick={() =>
                                    handleProductClick(itemData.slug)
                                  }
                                >
                                  <ProductCard itemData={itemData} t={t} />
                                </div>
                              ))}
                          </div>
                        </>
                      )}

                      <div className="text-right pt-5">
                        <Button
                          as={Link}
                          href={`/view-more-products/${encodeURIComponent(query)}`}
                          color="primary"
                          className="font-semibold px-8 rounded-3xl"
                        >
                          {t("Zobraziť viac produktov")}
                        </Button>
                      </div>
                    </>
                  )}
                </>
              ) : null}

              {/* Shops section */}
              {shops.length > 0 && (
                <>
                  <div className="py-8">
                    <hr />
                  </div>
                  <div className="font-bold text-2xl pb-6">Obchody</div>
                  <div className="pb-8">
                    <div className="flex flex-wrap gap-4">
                      {shops.map((shop) => (
                        <div key={shop.id}>
                          <ShopCard itemData={shop} />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchBar;
