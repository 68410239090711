import { Image, Button, Link } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import Translate from "./TranslationAPI";
import { withAdultContent } from "./hoc/withAdultContent";
import { useAdultContent } from "../providers/AdultContentProvider";

const ItemCard = ({ product, type }) => {
  const { t } = useTranslation();
  const typeColorMapping = {
    product: "bg-primary",
    food: "bg-green",
    travel: "bg-orange",
  };

  const typeSizeMapping = {
    travel: "scale-150",
  };

  const colorClass = typeColorMapping[type] || "bg-gray-500";

  // Function to clean HTML tags from text
  const cleanHtmlTags = (html) => {
    if (html == null) return ""; // Return empty string if html is null or undefined
    return html.replace(/<[^>]*>/g, "");
  };

  return (
    <>
      <div className="w-[300px] h-[450px] shadow-lg border-1 relative rounded-lg flex flex-col gap-4 hover:scale-105  transition duration-300">
        <div className=" rounded-xl relative h-[300px] w-[300px] flex justify-center items-center">
          {" "}
          <div
            className={`relative w-[280px] h-[280px] z-10 ${
              type === "travel" ? "bg-cover bg-center" : "bg-contain"
            }`}
            style={{
              backgroundImage: `url(${product.product_image_url})`,
              backgroundSize: type === "travel" ? "150%" : "contain",
              backgroundPosition: "center",
              borderRadius: "0.75rem",
            }}
          ></div>
        </div>
        <a className="bg-pink text-white py-1 font-semibold px-8 rounded-3xl absolute top-4 z-10 left-4">
          TIP
        </a>
        <div className="absolute top-52 right-0 z-10">
          <svg
            width="91"
            height="89"
            viewBox="0 0 91 89"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_bd_5780_1620)">
              <ellipse
                cx="45.0765"
                cy="40.1624"
                rx="22.0765"
                ry="21.1624"
                fill="black"
              />
              <path
                d="M66.6531 40.1624C66.6531 51.5543 57.0131 60.8249 45.0765 60.8249C33.14 60.8249 23.5 51.5543 23.5 40.1624C23.5 28.7706 33.14 19.5 45.0765 19.5C57.0131 19.5 66.6531 28.7706 66.6531 40.1624Z"
                stroke="url(#paint0_linear_5780_1620)"
              />
            </g>
            <path
              d="M52.557 33.3707C50.4208 31.6489 47.1204 31.9075 45.0766 33.9021C43.0328 31.9075 39.7324 31.6453 37.5962 33.3707C34.8169 35.6133 35.2235 39.2695 37.2045 41.2074L43.687 47.5384C44.0566 47.8998 44.5518 48.1017 45.0766 48.1017C45.6051 48.1017 46.0967 47.9033 46.4662 47.542L52.9488 41.2109C54.926 39.273 55.34 35.6168 52.557 33.3707ZM51.6848 40.0135L45.2023 46.3445C45.1136 46.4295 45.0397 46.4295 44.951 46.3445L38.4684 40.0135C37.1194 38.6955 36.846 36.2014 38.7382 34.6744C40.1759 33.5159 42.3934 33.6895 43.7831 35.0464L45.0766 36.3112L46.3702 35.0464C47.7672 33.6825 49.9847 33.5159 51.415 34.6709C53.3036 36.1979 53.0227 38.7062 51.6848 40.0135Z"
              fill="white"
            />
            <defs>
              <filter
                id="filter0_bd_5780_1620"
                x="0"
                y="0"
                width="90.1531"
                height="88.3248"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_5780_1620"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feMorphology
                  radius="1"
                  operator="erode"
                  in="SourceAlpha"
                  result="effect2_dropShadow_5780_1620"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_backgroundBlur_5780_1620"
                  result="effect2_dropShadow_5780_1620"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect2_dropShadow_5780_1620"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear_5780_1620"
                x1="27.573"
                y1="20.7821"
                x2="58.8"
                y2="66.3956"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="flex flex-col gap-2 px-4 ">
          <div className="font-bold">
            {product.product_price_min === null ? null : (
              <>
                {product.product_price_min === product.product_price_max
                  ? product.product_price_min
                  : product.product_price_min +
                    " - " +
                    product.product_price_max}
                {localStorage.getItem("locale") === "cs" ? "CZK" : "€"}
              </>
            )}
          </div>
          <div className="font-bold text-[#6B6B70] line-clamp-1">
            <Translate text={product.product_name} />
          </div>
          <div className="line-clamp-2 ">
            <Translate text={cleanHtmlTags(product.product_description)} />
          </div>
        </div>
        <div className="absolute -bottom-6 w-full ">
          <div className="flex justify-center">
            <Button
              className={`${colorClass} text-white rounded-3xl px-8`}
              as={Link}
              href={"/view-" + "product" + "/" + product.slug}
            >
              {t("Zobraziť")} &nbsp; &nbsp; &rarr;
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

// Wrap ItemCard with the adult content HOC
export default withAdultContent(ItemCard);
